import React from "react";
import Header from "../header/header";
import NavBar from "../nav/nav-bar";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Footer from "../footer/footer";
import Service from "../webservice/http";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import ClipLoader from "react-spinners/ClipLoader";

function AgentInsight() {
  const [edit, setEdit] = useState({});
  const { id } = useParams();
  // console.log(useParams());

  const services = new Service();
  const [Loading, setLoading] = useState(false);
  const [agentdata, setAgentdata] = useState("");
  const [editdata, setEditdata] = useState("");
  const [agentid, setAgentid] = useState("");

  // console.log(editdata);

  function fetchData() {
    setLoading(true);
    services.get(`api/report/agent_details/${id}/`).then((res) => {
      console.log(res);
      setAgentdata(res);
      setAgentid(res?.id);
      setLoading(false);
    });
  }
  const handleEditClick = (index) => {
    setEdit((prev) => ({ [index]: true }));
    setEditdata("");
  };

  console.log(edit);
  const handleSave = (id) => {
    services
      .patch(`api/report/agent_details/${agentid}/`, {
        id: id,
        voice_metrics_comments: editdata,
      })
      .then((res) => {
        // console.log("checkkkkkkk", res);
        // window.location.reload();
      });
    setEdit({});
    setEditdata("");
    fetchData();
  };

  // console.log(dataa);
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />
      <NotificationContainer />

      <div>
        <div className="container-fluid">
          <div className="my-call">
            <div className="">
              <div
                className="call-table-s"
                style={{ width: "100%", height: "auto" }}
              >
                <div className="my-calls-column">
                  <div className="calls-top-pannel">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="pannel-heading clearfix">
                          <div className="pannel-heading-icon">
                            <i
                              className="fa fa-snowflake-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="pannel-heading-info">
                            <h3>Agent Individual Scorecard </h3>
                            <p>Details</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div
                  className=" flex py-10"
                  style={{
                    border: "1px solid lightgray",
                    paddingLeft: "200px",
                    paddingRight: "200px",
                  }}
                >
                  <div
                    className="w-1/2 flex"
                    //
                  >
                    <div className=" flex  flex-col w-1/2 text-end mr-4">
                      <span className=" bg-green-500 text-white mb-2 py-4 px-10 ">
                        Name of Agent
                      </span>
                      <span className=" bg-green-500 text-white mb-2 py-4 px-10 ">
                        Department
                      </span>
                      <span className=" bg-green-500 text-white mb-2 py-4 px-10 ">
                        Account
                      </span>
                      <span className=" bg-green-500 text-white mb-2 py-4 px-10 ">
                        Team Lead
                      </span>
                      <span className=" bg-green-500 text-white mb-2 py-4 px-10 ">
                        Total calls
                      </span>
                    </div>
                    <div className=" flex  flex-col w-1/2 text-center">
                      <span className=" bg-gray-300 text-black mb-2 py-4  px-16 ">
                        {agentdata?.agent_name}
                      </span>
                      <span className=" bg-gray-300 text-black mb-2 py-4  px-16 ">
                        {agentdata?.department}
                      </span>
                      <span className=" bg-gray-300 text-black mb-2 py-4  px-16 ">
                        {agentdata?.account}
                      </span>
                      <span className=" bg-gray-300 text-black mb-2 py-4  px-16 ">
                        {agentdata?.team_lead}
                      </span>
                      <span className=" bg-gray-300 text-black mb-2 py-4  px-16 ">
                        {agentdata?.total_calls || 5}
                      </span>
                    </div>
                  </div>
                  <div
                    className=" w-1/2"
                    // style={{ border: "1px solid red" }}
                  >
                    <div
                      className=""
                      //   style={{ paddingBottom: "30px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={services.domain + agentdata?.agent_image}
                          alt="Admin"
                          class="p-1 bg-primary rounded-full"
                          // width="125"
                          // height="125"
                          style={{ height: "125px", width: "125px" }}
                        />
                      </div>
                      <span
                        className=" flex justify-center mt-6 text-black text-2xl font-extrabold uppercase"
                        // style={{ margin: "20px" }}
                      >
                        Score
                      </span>
                      <span className=" flex justify-center mt-4">
                        <div
                          className=" h-20 flex justify-center items-center bg-gray-100 text-3xl font-bold"
                          //   style={{ border: "1px solid blue" }}
                          style={{ width: "50%" }}
                        >
                          {agentdata?.score}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="pb-24 mt-6">
                  <div className="overflow-x-auto">
                    <table
                      className="m"
                      style={{ border: "1px solid lightgray", width: "100%" }}
                    >
                      <thead className=" border-b-2 lightgray">
                        <tr>
                          <th className="px-6 py-3 text-left text-xl  text-black font-extrabold uppercase tracking-wider">
                            Metrics
                          </th>
                          <th className="px-6 py-3 text-left text-xl  text-black font-extrabold uppercase tracking-wider">
                            Weightage
                          </th>
                          <th className="px-6 py-3 text-left text-xl  text-black font-extrabold uppercase tracking-wider">
                            Score
                          </th>
                          <th className="px-6 py-3 text-left text-xl  text-black font-extrabold uppercase tracking-wider">
                            Weighted Score
                          </th>
                          <th className="px-6 py-3 text-left text-xl  text-black font-extrabold uppercase tracking-wider">
                            Notes/comments
                          </th>
                        </tr>
                      </thead>

                      {Loading && (
                        <tr>
                          <td colSpan="10" style={{ textAlign: "center" }}>
                            <ClipLoader color="#2056FF" size="50px" />
                          </td>
                        </tr>
                      )}

                      <tbody className="agent-scorecard">
                        <tr
                          className="bg-white "
                          style={{ backgroundColor: "white" }}
                        >
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "18px",
                              backgroundColor: "#F5F7F8",
                            }}
                            className="p-4"
                          >
                            Voice
                          </td>
                        </tr>
                        {agentdata?.metrics?.map((e, index) => (
                          <tr
                            key={index}
                            className={`m-4 ${
                              index % 2 === 0 ? " bg-gray-200" : "bg-white"
                            }`}
                          >
                            <td className="px-6 py-4 whitespace-nowrap text-xl text-black">
                              {e?.voice_metrics}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-xl text-black">
                              {e?.voice_weighting}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-xl text-black">
                              {e?.voice_score}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-xl text-black">
                              {e?.voice_wighted_score}
                            </td>
                            {!edit[index] ? (
                              <td className="px-6 py-4 whitespace-nowrap text-xl text-black flex">
                                {e?.voice_metrics_comments}{" "}
                                <span
                                  className=" ml-8 cursor-pointer"
                                  onClick={() => handleEditClick(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-pencil"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                  </svg>
                                </span>
                              </td>
                            ) : (
                              <td className="px-6 py-4 whitespace-nowrap text-xl text-black flex">
                                <input
                                  type="text"
                                  defaultValue={e?.voice_metrics_comments}
                                  className="py-1 rounded-xl px-4 "
                                  style={{
                                    border: "1px solid lightgray",
                                    width: "400px",
                                  }}
                                  onChange={(e) => {
                                    setEditdata(e.target.value);
                                  }}
                                ></input>
                                {/* {e?.comments}{" "} */}
                                <span className=" ml-8 cursor-pointer">
                                  <button
                                    className="px-4 py-1"
                                    style={{ border: "1px solid lightgray" }}
                                    onClick={() => handleSave(e?.id)}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="px-4 py-1 ml-2"
                                    style={{ border: "1px solid lightgray" }}
                                    onClick={() => {
                                      setEdit((prevEdit) => ({
                                        ...prevEdit,
                                        [index]: false,
                                      }));
                                      setEditdata(false);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AgentInsight;
