import React from "react";

import NavBar from "../../nav/nav-bar";
import Header from "../../header/header";
import ReportNav from "../report-nav";
import Footer from "../../footer/footer";
import Dashscript from "../../dashscript";
import { useState } from "react";
import { useEffect } from "react";
import { event } from "jquery";
import DatePicker from "react-multi-date-picker";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import moment from "moment";
import Service from "../../webservice/http";
import { Loader } from "rsuite";
import { debounce } from "lodash";
import axios from "axios";
import { async } from "q";
import ClipLoader from "react-spinners/ClipLoader";
import Viewmore from "./viewmore";
import { FaFilter } from "react-icons/fa";
import Filter from "../filter";
import { useHistory } from "react-router-dom";
import config from "../../config";

function Report() {
  const tableHeadingArray = [
    " Call Date ",
    "Audit Date",
    "Customer ID",
    "Product",
    "Agent",
    "Call Language",
    "Manager",
    "Reviewer",
    "Action",
  ];

  const [showNewModal, setShowNewModal] = useState(false);
  const defaultDate = [
    moment().subtract(1, "months").format("DD-MM-YYYY"),
    moment().format("DD-MM-YYYY"),
  ];

  const services = new Service();
  const history = useHistory();

  const [type, setType] = useState("");
  const [tabledata, setTabledata] = useState([]);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState([
    new Date().setMonth(new Date().getMonth() - 1),
    new Date(),
  ]);
  const [range, setRange] = useState("");

  const [Loading, setLoading] = useState(false);

  // filter icon
  const [sideBarWidth, setSideBarWidth] = useState(0);
  const [filter, setFilter] = useState(false);
  const [filterError, setFilterError] = useState(false);

  const changeSideBarWidth = () => {
    if (sideBarWidth == 570) {
      setSideBarWidth(0);
    } else {
      setSideBarWidth(570);
    }
  };

  async function Dashbaorddata() {
    setLoading(true);

    let url = `api/call_verification/verification_reports/`;

    if (range.length == 2) {
      url = url + `?from_date=${range[0]}&to_date=${range[1]}`;
    }
    if (search) {
      url =
        range.length == 2
          ? url + `&search=${search}`
          : url + `?search=${search}`;
    }

    const res = await services
      .get(url)

      .then((res) => {
        setTabledata(res?.data);
        setLoading(false);
      });
  }

  console.log("tabledata", tabledata);

  const debouncedSearch = debounce((value) => {
    setSearch(value.toLowerCase());
  }, 500);

  const handleDates = (dates) => {
    if (dates) {
      const range = dates.map((date) => {
        return date.format("DD/MM/YYYY");
      });
      setRange(range);
    }
  };

  // console.log(localStorage.getItem("access_token"));

  const downloadReport = async function () {
    let downloadUrl = `${config.API_BASE_URL}/api/call_verification/export_dasboard/`;
    if (range.length == 2) {
      downloadUrl = downloadUrl + `?from_date=${range[0]}&to_date=${range[1]}`;
    }
    if (search) {
      downloadUrl =
        range.length == 2
          ? downloadUrl + `&search=${search}`
          : downloadUrl + `?search=${search}`;
    }
    if (_product_name) {
      downloadUrl =
        range.length == 2 || search
          ? downloadUrl + `&product=${_product_name}`
          : downloadUrl + `?product=${_product_name}`;
    }
    if (agent) {
      downloadUrl =
        range.length == 2 || search || _product_name
          ? downloadUrl + `&agent=${agent}`
          : downloadUrl + `?agent=${agent}`;
    }
    if (language) {
      downloadUrl =
        range.length == 2 || search || _product_name || agent
          ? downloadUrl + `&language=${language}`
          : downloadUrl + `?language=${language}`;
    }
    if (lead) {
      downloadUrl =
        range.length == 2 || search || _product_name || agent
          ? downloadUrl + `&team_lead=${lead}`
          : downloadUrl + `?team_lead=${lead}`;
    }
    try {
      const response = await axios.get(downloadUrl, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      // console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // console.log(url);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dashboard_data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const [filterOptions, setFilterOptions] = useState("");

  async function filterData() {
    try {
      const res = await services
        .get("api/call_verification/filter_parameters/")
        .then((res) => setFilterOptions(res));
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => filterData(), []);

  // console.log("nooopnoppo", filterOptions);

  const [_product_name, set_product_name] = useState("");
  const [agent, setAgent] = useState("");
  const [language, setLanguage] = useState("");
  const [lead, setlead] = useState("");

  const clearFilter = function () {
    setAgent("");
    set_product_name("");
    setLanguage("");
    setlead("");
    Dashbaorddata();
  };

  // console.log(language);

  const applyFilter = async function () {
    if (search || _product_name || agent || lead || language) {
      let url = `api/call_verification/verification_reports/`;
      if (range.length == 2) {
        url = url + `?from_date=${range[0]}&to_date=${range[1]}`;
      }
      if (search) {
        url =
          range.length == 2
            ? url + `&search=${search}`
            : url + `?search=${search}`;
      }
      if (_product_name) {
        url =
          range.length == 2 || search
            ? url + `&product=${_product_name}`
            : url + `?product=${_product_name}`;
      }
      if (agent) {
        url =
          range.length == 2 || search || _product_name
            ? url + `&agent=${agent}`
            : url + `?agent=${agent}`;
      }
      if (language) {
        url =
          range.length == 2 || search || _product_name || agent
            ? url + `&language=${language}`
            : url + `?language=${language}`;
      }
      if (lead) {
        url =
          range.length == 2 || search || _product_name || agent
            ? url + `&team_lead=${lead}`
            : url + `?team_lead=${lead}`;
      }

      const res = await services.get(url);
      setTabledata(res?.data);
      // console.log("filter applied", res);
      // Dashbaorddata();
      setFilter(false);
    } else {
      setFilterError(true);
    }
  };

  // console.log(agent, _product_name, language, lead);

  useEffect(() => {
    Dashbaorddata();
  }, [range, search]);

  return (
    <div>
      <Header />
      <NavBar />

      <div>
        <div className="container-fluid" style={{ background: "#EEF2F5" }}>
          <div className="my-call">
            <div
              className="side-panel-with-table clearfix flex"
              style={{ display: "flex" }}
            >
              <ReportNav />
              <div className="call-table" style={{ marginBottom: "80px" }}>
                <div
                  className="my-calls-column"
                  style={{ padding: "15px 0px 0px 15px" }}
                >
                  <div className="calls-top-pannel">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="pannel-heading clearfix">
                          <div className="pannel-heading-icon">
                            <i
                              className="fa fa-snowflake-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="pannel-heading-info">
                            <h3>Reports</h3>
                            <p> Details</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" flex justify-end  mr-10 items-center">
                  <div className=" inline-block">
                    <div
                      className="rounded-md border-gray-300 bg-white"
                      style={{ width: "200px" }}
                    >
                      <DatePicker
                        // defaultValue="Select Date Range"

                        value={value}
                        onChange={(date) => {
                          handleDates(date);
                        }}
                        range={true}
                        maxDate={
                          true
                            ? new Date(
                                new Date().setMonth(new Date().getMonth() + 0)
                              )
                            : null
                        }
                        style={{
                          marginRight: "20px",
                          width: "180px",
                          height: "35px",
                          borderRadius: "15px",
                          background: "#eef2f5",
                          // border: "1px solid black",
                          border: "none",
                          color: "black",
                          fontWeight: "normal",
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace") {
                            e.preventDefault();
                          }
                        }}
                        format="DD/MM/YYYY"
                        plugins={[
                          <Toolbar
                            key="date-pick-btn"
                            position="bottom"
                            names={{
                              today: "Today",
                              deselect: "Reset",
                              close: "Close",
                            }}
                          />,
                        ]}
                      />
                    </div>
                  </div>

                  {/* <div className="mr-6 inline-block rounded-lg border"> */}
                  <input
                    onChange={(event) => debouncedSearch(event.target.value)}
                    type="text"
                    placeholder="Search..."
                    className="search-tag"
                    style={{
                      // border: "1px solid transparent",
                      border: "none",
                      background: "#eef2f5",
                      borderRadius: "10px",
                      width: "250px",
                      outline: "none",
                      paddingLeft: "8px",
                      height: "35px",
                    }}
                  />
                  {/* </div> */}

                  <div
                    className="inline-block cursor-pointer ml-6 filter-funnel-primary"
                    onClick={() => setFilter(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-funnel"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                    </svg>

                    {/* <div className="text-box">
                Additional Filters
              </div> */}
                  </div>
                </div>

                <div className=" flex justify-end m-6">
                  <button
                    className={` text-white px-8 py-4 rounded-xl export-btn tracking-wide ${
                      !tabledata?.length ? " opacity-60" : ""
                    }`}
                    disabled={!tabledata?.length}
                    onClick={downloadReport}
                  >
                    Export
                  </button>
                </div>

                <div className=" overflow-x-scroll">
                  <table
                    className="ss mb-40"
                    style={{ border: "1px solid grey" }}
                  >
                    <tr id="black-line">
                      {tableHeadingArray.map((item, indx) => (
                        <th
                          key={item}
                          className={`
                               left-0 bg-white  border-b-2 border-gray-200 px-5 py-4 text-left  text-xl font-semibold  tracking-wider text-black  
                                
                               }  `}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>

                    <tbody>
                      {Loading && (
                        <tr>
                          <td colSpan="11" style={{ textAlign: "center" }}>
                            <ClipLoader color="#2056FF" size="50px" />
                          </td>
                        </tr>
                      )}

                      {tabledata.length ? (
                        tabledata.map((e, i) => {
                          return (
                            <tr
                              key={i}
                              // className={`${
                              //   e["Call Status"] === "Fatal"
                              //     ? " bg-red-500"
                              //     : " bg-green-500"
                              // }`}
                              className=" cursor-pointer"
                              // style={{ background: "red" }}
                              onClick={() =>
                                history.push(`/detailed-reports/${e.id}`)
                              }
                            >
                              <td
                                className={`border-b border-gray-200 px-5 py-2   text-xs first-letter `}
                              >
                                <div>
                                  <p className="whitespace-no-wrap cursor-pointer text-center text-sky-700">
                                    {e.call_date}
                                  </p>
                                </div>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2  text-xs`}
                                id="date"
                              >
                                <p className="whitespace-nowrap text-left text-gray-900 ">
                                  {e.audit_date}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2    text-xs`}
                                id="date"
                              >
                                <p className="whitespace-nowrap text-left text-gray-900">
                                  {e?.customer_name}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2   text-xs`}
                              >
                                <p className="whitespace-no-wrap text-left text-gray-900">
                                  {e?.product}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2   text-xs sticky left-0 `}
                              >
                                <p className="whitespace-nowrap text-left text-gray-900">
                                  {e?.agent}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2   text-xs sticky left-0 `}
                              >
                                <p className="whitespace-nowrap text-left text-gray-900">
                                  {e?.language}
                                </p>
                              </td>

                              <td
                                className={`border-b border-gray-200 px-5 py-2   text-xs whitespace-nowrap`}
                              >
                                <p className="  text-left text-gray-900">
                                  {e?.team_lead}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2   text-xs whitespace-nowrap`}
                              >
                                <p className="  text-left text-gray-900">
                                  {e?.qa_lead}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2  w-40 text-xs cursor-pointer`}
                              >
                                <a className="text-left text-gray-900 whitespace-nowrap">
                                  View More
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : Loading ? (
                        ""
                      ) : (
                        <tr>
                          <td colSpan="11" style={{ textAlign: "center" }}>
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {filter && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div
            className="bg-white p-6 rounded-lg shadow-lg relative max-w-full"
            style={{ height: "400px", width: "600px" }}
          >
            <button
              className="absolute top-4 right-3 text-gray-600 hover:text-gray-900 font-bold"
              onClick={() => {
                setFilter(false);
                setFilterError(false);
              }}
              style={{ fontSize: "25px" }}
            >
              <svg
                focusable="false"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                class=" NMm5M"
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
              </svg>
            </button>
            <div
              className=" text-mhgrey-dark  flex items-center text-4xl justify-center font-bold p-4 pb-10 "
              style={{ borderBottom: "1px solid lightgray" }}
            >
              Additional Filter
            </div>
            <div
              className=" flex justify-around "
              style={{ marginTop: "40px" }}
            >
              <div className=" flex flex-col" style={{ width: "40%" }}>
                <span
                  className=" mb-4 font-medium "
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                >
                  Product name
                </span>
                <select
                  className=" rounded-xl p-3"
                  style={{ border: "1px solid lightgray" }}
                  onChange={(e) => {
                    setFilterError(false);
                    set_product_name(e.target.value);
                  }}
                  value={_product_name}
                >
                  <option selected> -- select an option -- </option>
                  {filterOptions?.products.map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                  {/* <option>hy</option>
                  <option>hy</option>
                  <option>hy</option> */}
                </select>
              </div>
              <div className=" flex flex-col" style={{ width: "40%" }}>
                <span
                  className=" mb-4 font-medium"
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                >
                  Agent
                </span>
                <select
                  className=" rounded-xl p-3"
                  // onChange={(e) => setDrop(e.target.value)}
                  style={{ border: "1px solid lightgray" }}
                  onChange={(e) => {
                    setFilterError(false);
                    setAgent(e.target.value);
                  }}
                  value={agent}
                >
                  <option selected>-- select an option -- </option>
                  {filterOptions?.agents.map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className=" flex mt-10  justify-around">
              <div className=" flex flex-col" style={{ width: "40%" }}>
                <span
                  className=" mb-4 font-medium "
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                >
                  Call Language
                </span>
                <select
                  className=" rounded-xl p-3"
                  style={{ border: "1px solid lightgray" }}
                  onChange={(e) => {
                    setFilterError(false);
                    setLanguage(e.target.value);
                  }}
                  value={language}
                >
                  <option selected> -- select an option -- </option>
                  {filterOptions?.call_languages.map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                </select>
              </div>
              <div className=" flex flex-col" style={{ width: "40%" }}>
                <span
                  className=" mb-4 font-medium"
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                >
                  Team Lead
                </span>
                <select
                  className=" rounded-xl p-3"
                  style={{ border: "1px solid lightgray" }}
                  onChange={(e) => {
                    setFilterError(false);
                    setlead(e.target.value);
                  }}
                  value={lead}
                >
                  <option selected> -- select an option -- </option>
                  {filterOptions?.team_leads.map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                </select>
              </div>
            </div>
            {filterError && (
              <p className=" px-12 py-4 text-red-700 flex items-center">
                <span>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                  </svg>
                </span>
                <span className=" ml-3">No filters selected</span>
              </p>
            )}
            <div className=" absolute bottom-10 mb-6" style={{ right: "36px" }}>
              <button
                className={`  px-8 py-4 rounded-xl export-btn tracking-wide mr-4 bg-gray-200 text-black   hover:bg-orange-600 hover:text-white  font-medium`}
                // disabled={!tabledata?.length}
                onClick={clearFilter}
              >
                Clear Filter
              </button>
              <button
                className={` px-8 py-4 rounded-xl export-btn tracking-wide bg-gray-200 text-black  hover:bg-[#271078] hover:text-white  font-medium`}
                // disabled={!tabledata?.length}
                onClick={applyFilter}
              >
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default Report;
