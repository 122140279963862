import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Service from "./../webservice/http";
import { useHistory } from "react-router-dom";

const NavBar = () => {
  const services = new Service();
  var url = window.location.href;
  url = url.replace(/^.*\/\/[^\/]+/, "");
  const [permission, setPermission] = useState();

  const history = useHistory();

  /**
   * Fetch all the permission details for the logged in user
   *
   * Based on permission details nav will be populated
   *
   */
  function fetch_permissionDetails() {
    services.get("api/access_control/permission_details/").then((res) => {
      // console.log(res);
      if (res == "TypeError: Failed to fetch") {
      } else {
        try {
          if (res.code == "token_not_valid") {
            localStorage.clear();
            history.push("/login");
          }
          setPermission(res);
        } catch (e) {
          // console.log(e);
        }
      }
    });
  }

  useEffect(() => {
    fetch_permissionDetails();
  }, [url]);

  return (
    <div className="main-nav clearfix">
      <div className="sale" style={{ fontSize: "14px" }}>
        <div>
          <img
            src="/assets/images/grid.png"
            className="img-responsive"
            alt=""
          />{" "}
          {localStorage.getItem("usecase") === "Complaints Management"
            ? "Customer Service"
            : localStorage.getItem("client_name") === "Standard Chartered"
            ? "Banking-Collections"
            : localStorage.getItem("client_name") === "General Collections"
            ? "Banking-Collection"
            : localStorage.getItem("client_name") === "Maya Bank Collections"
            ? "Banking Collections"
            : localStorage.getItem("usecase")}
        </div>
      </div>
      <div className="main-menu">
        <nav className="nav-primary">
          <ul key="ul" className="menu-main-navigation menu clearfix">
            <li key="home" className={url === "/" ? "active" : ""}>
              <Link to={"/"}>Home</Link>
            </li>
            {permission &&
              permission.map((p) => (
                <>
                  {p.feature == "Call List" && (
                    <li
                      key={p.feature}
                      className={
                        url.includes("ticket-list") ||
                        url.includes("call-list") ||
                        url.includes("call-insight") ||
                        url.includes("deep-analysis")
                          ? "active"
                          : ""
                      }
                    >
                      {" "}
                      {localStorage.getItem("usecase") ===
                      "Complaints Management" ? (
                        <Link to={"/ticket-list"}>Tickets</Link>
                      ) : (
                        <Link to={"/call-list"}>Calls</Link>
                      )}
                    </li>
                  )}
                </>
              ))}

            {permission &&
              permission.map((p) => (
                <>
                  {p.feature == "Dashboard" && (
                    <li
                      key={p.feature}
                      className={url === "/dashboard" ? "active" : ""}
                    >
                      {" "}
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                  )}
                </>
              ))}

            {permission &&
              localStorage.getItem("usecase") !== "Complaints Management" &&
              permission.map((p) => (
                <>
                  {p.feature == "Opportunity" && (
                    <li
                      key={p.feature}
                      className={url === "/call/opportunities" ? "active" : ""}
                    >
                      {" "}
                      <Link to={"/call/opportunities"}>Opportunities</Link>
                    </li>
                  )}
                </>
              ))}
            {permission &&
              permission.map((p) => (
                <>
                  {p.feature == "Promise to Pay" && (
                    <li
                      key={p.feature}
                      className={url === "/call/promise-to-pay" ? "active" : ""}
                    >
                      {" "}
                      <Link to={"/call/promise-to-pay"}>Promise to Pay</Link>
                    </li>
                  )}
                </>
              ))}

            {permission &&
              localStorage.getItem("usecase") !== "Complaints Management" &&
              permission.map((p) => (
                <>
                  {p.feature == "Service Request" && (
                    <li
                      key={p.feature}
                      className={
                        url === "/call/service-requests" ||
                        url === "/ticket/service-requests"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={"/call/service-requests"}>
                        {" "}
                        Service Requests
                      </Link>
                    </li>
                  )}
                </>
              ))}

            {permission &&
              permission.map((p) => (
                <>
                  {p.feature == "Archrive Call" && (
                    <li
                      key={p.feature}
                      className={
                        url === "/call/archive-calls" ||
                        url === "/ticket/archive-calls"
                          ? "active"
                          : ""
                      }
                    >
                      {" "}
                      {localStorage.getItem("usecase") ===
                      "Complaints Management" ? (
                        <Link to={"/ticket/archive-tickets"}>
                          Archived Tickets
                        </Link>
                      ) : (
                        <Link to={"/call/archive-calls"}>Archived Calls</Link>
                      )}
                    </li>
                  )}
                </>
              ))}

            {permission &&
              permission.map((p) => (
                <>
                  {p.feature == "Contact" && (
                    <li
                      key={p.feature}
                      className={
                        url.substr(0, 8) === "/contact" ? "active" : ""
                      }
                    >
                      {" "}
                      <Link to={"/contact"}>Contact</Link>
                    </li>
                  )}
                </>
              ))}

            {permission &&
              permission.map((p) => (
                <>
                  {p.feature == "Task" && (
                    <li
                      key={p.feature}
                      className={url === "/task" ? "active" : ""}
                    >
                      {" "}
                      <Link to={"/task"}>Task</Link>
                    </li>
                  )}
                </>
              ))}

            {permission &&
              permission.map((p) => (
                <>
                  {p.feature == "User Management" && (
                    <li
                      key={p.feature}
                      className={
                        url.substr(0, 16) === "/user-management" ? "active" : ""
                      }
                    >
                      {" "}
                      <Link to={"/user-management/manage-user"}>
                        User Management
                      </Link>
                    </li>
                  )}
                </>
              ))}

            {permission &&
              permission.map((p) => (
                <>
                  {p.feature == "Quality Audit" && (
                    <li
                      key={p.feature}
                      className={
                        url.substr(0, 16) === "/quality-audit" ? "active" : ""
                      }
                    >
                      {" "}
                      <Link to={"/quality-audit"}>Quality Audit</Link>
                    </li>
                  )}
                </>
              ))}

            {permission &&
              permission.map((p) => (
                <>
                  {p.feature == "Report" &&
                    (localStorage.getItem("usecase") !==
                      "Insurance - PCVC Verification" &&
                    localStorage.getItem("client") !== "DBS Bank Singapore" &&
                    localStorage.getItem("client") !== "SP Madrid" &&
                    localStorage.getItem("client") !==
                      "Maya Bank Collections" &&
                    localStorage.getItem("client") !== "HDFC Life Digital" ? (
                      <li
                        key={p.feature}
                        className={
                          url.substr(0, 8) === "/reports" ? "active" : ""
                        }
                      >
                        {" "}
                        <Link to={"/reports/customer-intent"}>Reports</Link>
                      </li>
                    ) : localStorage.getItem("client") ===
                      "DBS Bank Singapore" ? (
                      <li
                        key={p.feature}
                        className={
                          url.substr(0, 8) === "/reports" ? "active" : ""
                        }
                      >
                        {" "}
                        <Link to={"/reports/forex"}>Reports</Link>
                      </li>
                    ) : localStorage.getItem("client") === "SP Madrid" ? (
                      <li
                        key={p.feature}
                        className={
                          url.substr(0, 8) === "/reports" ? "active" : ""
                        }
                      >
                        {" "}
                        <Link to={"/reports/promise-to-pay"}>Reports</Link>
                      </li>
                    ) : localStorage.getItem("usecase") ===
                      "Banking Collections PCVC" ? (
                      <li
                        key={p.feature}
                        className={
                          url.substr(0, 8) === "/reports" ||
                          url.includes("/detailed-reports")
                            ? "active"
                            : ""
                        }
                      >
                        {" "}
                        <Link to={"/reports"}>Reports</Link>
                      </li>
                    ) : localStorage.getItem("client") ===
                      "HDFC Life Digital" ? (
                      <li
                        key={p.feature}
                        className={url.includes("/reports") ? "active" : ""}
                      >
                        {" "}
                        <Link to={"/reports/reports-dashboard"}>Reports</Link>
                      </li>
                    ) : (
                      <li
                        key={p.feature}
                        className={
                          url.substr(0, 8) === "/reports" ? "active" : ""
                        }
                      >
                        {" "}
                        <Link to={"/reports/dashboard"}>Reports</Link>
                      </li>
                    ))}
                </>
              ))}
          </ul>
        </nav>
        <div className="toggle-menu">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
