import Header from "./header/header";
import Footer from "./footer/footer";
import NavBar from "./nav/nav-bar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./home.css";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import histogram from "highcharts/modules/histogram-bellcurve.js";
import Masonry from "react-masonry-css";
import Service from "./webservice/http";
import ClipLoader from "react-spinners/ClipLoader";
import { BiError, BiCommentAdd, BiTaskX } from "react-icons/bi";
import { RiSignalWifiErrorFill } from "react-icons/ri";
import { MdAddIcCall } from "react-icons/md";
import { RiChatVoiceLine } from "react-icons/ri";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import { MdIntegrationInstructions } from "react-icons/md";
import Dropdown from "rsuite/Dropdown";
import { Items } from "./Items";

const Home = () => {
  const history = useHistory();
  const services = new Service();
  var useCase = localStorage.getItem("usecase");

  // console.log(useCase);
  const [cxScoreData, setCXScoreData] = useState();
  const [sentimentData, setSentimentData] = useState();
  const [recentCalls, setRecentCalls] = useState(null);
  const [managerComment, setManagerComment] = useState(null);
  const [leadStatus, setLeadStatus] = useState(null);
  const [serviceRequestStatus, setServiceRequestStatus] = useState(null);
  const [pendingTask, setPendingTask] = useState(null);
  const [callBack, setCallBack] = useState(null);

  const [productDetail, setProductDetail] = useState("");
  const [product, setProduct] = useState("");
  const [productType, setProductType] = useState("");
  const [activetab, setActivetab] = useState("Resolutions");

  histogram(Highcharts);

  function fetchProduct() {
    services.get("api/call/new_call/").then((res) => {
      // console.log("mmmmmmmmm", res);
      setProductDetail(res);
    });
  }
  useEffect(() => {
    fetchProduct();
  }, []);

  // console.log(product);
  // console.log("this one", productName);

  /**
   * Fetch homepage data onload
   *
   */
  useEffect(() => {
    fetchFullScriptData();
    fetchRecentCall();
    fetchManagerComment();
    fetchOpportunities();
    fetchServiceRequest();
    fetchPendingTask();
    fetchCallBackRequests();

    fetchCxScoreTrend();
    fetchIntentData();
    var newDate = new Date();
    // var tempData = [];
    // for (let i = 7; i > 0; i--) {
    //     newDate = new Date();
    //     newDate.setDate(newDate.getDate() - i);
    //     //console.log(newDate);
    //     tempData.push(
    //         [newDate, Math.floor(Math.random() * (65 - 35) + 35)]
    //     )
    // }
    // setCXScoreData(tempData);
    var sentiment_list = [
      -0.7, -0.7, -0.7, -0.7, -0.7, -0.7, -0.6, -0.6, -0.6, -0.6, -0.5, -0.5,
      -0.5, -0.5, -0.5, -0.5, -0.5, -0.5, -0.5, -0.5, -0.5, -0.5, -0.4, -0.4,
      -0.4, -0.4, -0.4, -0.4, -0.4, -0.4, -0.3, -0.3, -0.3, -0.3, -0.3, -0.3,
      -0.2, -0.2, -0.2, -0.2, -0.1, -0.1, 0.0, 0.0, 0.0, 0.1, 0.1, 0.1, 0.1,
      0.1, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.4,
      0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4,
      0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5,
      0.5, 0.6, 0.6, 0.6, 0.6, 0.6, 0.7, 0.7,
    ];
    setSentimentData(sentiment_list);
  }, []);

  /**
   * Fetch CX Score trend
   */
  function fetchCxScoreTrend() {
    var today = new Date();
    var endDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    today.setDate(today.getDate() - 180);
    var startDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    // console.log(start_date, end_date)

    services
      .get(`api/report/${startDate}/${endDate}/cx_score_trend/`)
      .then((res) => {
        // console.log(res);
        // setIsPending(false);
        if (res == "TypeError: Failed to fetch") {
          // setError('Connection Error');
        } else {
          try {
            if (res.code == "token_not_valid") {
              localStorage.clear();
              history.push("/login");
            }
            var cxData = [];
            var dates = [];
            res.map((item) => {
              cxData.push([item.call_date, item.AverageCX]);
              dates.push(item.call_date);
            });
            setCXScoreData(cxData);
            setDisplayDateCategory(dates);
          } catch (e) {
            // setError(e);
          }
        }
      });
  }

  const [displayDateCategory, setDisplayDateCategory] = useState([]);

  /**
   * set CX Trend chart config
   */
  const cxTrendOptions = {
    chart: {
      type: "scatter",
      height: (6 / 14) * 100 + "%",
      //margin: [70, 50, 60, 80]
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: displayDateCategory,
      gridLineWidth: 0,
      //minPadding: 0.2,
      //maxPadding: 0.2,
      //maxZoom: 60,
      type: "datetime",
      dateTimeLabelFormats: {
        // don't display the dummy year
        month: "%e. %b",
        year: "%Y",
      },
      title: {
        text: "Date",
      },
    },
    yAxis: {
      gridLineColor: "transparent",
      title: {
        text:
          localStorage.getItem("usecase") == "Complaints Management"
            ? "Aggregated Complaints Intent "
            : "Aggregated CX Score",
      },
      //minPadding: 0.2,
      //maxPadding: 0.2,
      //maxZoom: 60,
      plotLines: [
        {
          value: 0,
          width: 0,
          color: "#808080",
        },
      ],
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      series: {
        lineWidth: 1,
      },
    },
    series: [
      {
        data: cxScoreData,
      },
    ],
  };

  const [intentDateCategory, setIntentDateCategory] = useState(null);
  const [aggregatedCustomerIntentData, setAggregatedCustomerIntentData] =
    useState([]);

  /**
   * Fetch Intent Data for Home Page
   */
  function fetchIntentData() {
    var today = new Date();
    var endDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    today.setDate(today.getDate() - 180);
    var startDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    // console.log(start_date, end_date)

    services
      .get(`api/report/${startDate}/${endDate}/customer_intent/`)
      .then((res) => {
        // console.log(res);
        // setIsPending(false);
        if (res == "TypeError: Failed to fetch") {
          // setError('Connection Error');
        } else {
          try {
            if (res.code == "token_not_valid") {
              localStorage.clear();
              history.push("/login");
            }

            var p = [];
            var n = [];
            var d = [];
            res.intent.map((item) => {
              p.push(item.Positive);
              n.push(item.Negative);
              d.push(item.call_date);
            });
            setAggregatedCustomerIntentData([p, n]);
            setIntentDateCategory(d);
          } catch (e) {
            // setError(e);
          }
        }
      });
  }

  /**
   * Customer Intent
   */
  const aggregatedCustomerIntentOptions = {
    chart: {
      type: "column",
      height: (8 / 19) * 100 + "%",
    },

    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      gridLineWidth: 0,
      categories: intentDateCategory,
    },
    yAxis: {
      // gridLineWidth: 0,
      gridLineColor: "transparent",
    },
    yAxis: [
      {
        className: "highcharts-color-0",
        title: {
          text: "Positive Intent",
        },
      },
      {
        className: "highcharts-color-1",
        opposite: true,
        title: {
          text: "Negative Intent",
        },
      },
    ],

    plotOptions: {
      column: {
        borderRadius: 5,
      },
    },

    series: [
      {
        data: aggregatedCustomerIntentData[0],
        name: "Positive",
      },
      {
        data: aggregatedCustomerIntentData[1],
        name: "Negative",
        yAxis: 1,
      },
    ],
  };

  const [recentCallPending, setRecentCallPending] = useState(true);
  const [recentCallError, setRecentCallError] = useState(null);

  /**
   * Fetch recent 5 Calls from API to display in home page
   */

  function fetchRecentCall() {
    setRecentCallPending(true);
    setRecentCallError(null);
    setRecentCalls(null);
    services.get("api/call/home_page/recent_calls/").then((res) => {
      if (res == "TypeError: Failed to fetch") {
        setRecentCallPending(false);
        setRecentCallError("Connection Error");
      } else {
        try {
          if (res.code == "token_not_valid") {
            localStorage.clear();
            history.push("/login");
          }
          setRecentCallPending(false);
          setRecentCalls(res);
        } catch (e) {
          setRecentCallError(e);
        }
      }
    });
  }

  // console.log("nono", recentCalls);

  const [managerCommentPending, setManagerCommentPending] = useState(true);
  const [managerCommentError, setManagerCommentError] = useState(null);

  /**
   * Fetch Manager Comment // Call Review to display in home page
   */

  function fetchManagerComment() {
    setManagerComment(null);
    setManagerCommentPending(true);
    setManagerCommentError("");
    services.get("api/call/home_page/managers_comments/").then((res) => {
      if (res == "TypeError: Failed to fetch") {
        setManagerCommentPending(false);
        setManagerCommentError("Connection Error");
      } else {
        try {
          if (res.code == "token_not_valid") {
            localStorage.clear();
            history.push("/login");
          }
          setManagerCommentPending(false);
          setManagerComment(res);
        } catch (e) {
          setManagerCommentError(e);
        }
      }
    });
  }

  const [opportunityPending, setOpportunityPending] = useState(true);
  const [opportunityError, setOpportunityError] = useState(null);
  /**
   * Fetch recent 5 opportunities to display in frontend
   */

  function fetchOpportunities() {
    setOpportunityPending(true);
    setOpportunityError("");
    setLeadStatus(null);
    services.get("api/call/home_page/lead_status/").then((res) => {
      if (res == "TypeError: Failed to fetch") {
        setOpportunityPending(false);
        setOpportunityError("Connection Error");
      } else {
        try {
          if (res.code == "token_not_valid") {
            localStorage.clear();
            history.push("/login");
          }
          setOpportunityPending(false);
          setLeadStatus(res);
        } catch (e) {
          setOpportunityError(e);
        }
      }
    });
  }

  const [serviceRequestPending, setServiceRequestPending] = useState(true);
  const [serviceRequestError, setServiceRequestError] = useState(null);

  /**
   * Fetch recent 5 service requests to display in frontend
   */
  function fetchServiceRequest() {
    setServiceRequestError("");
    setServiceRequestPending(true);
    setServiceRequestStatus(null);
    services.get("api/call/home_page/service_request_status/").then((res) => {
      if (res == "TypeError: Failed to fetch") {
        setServiceRequestPending(false);
        setServiceRequestError("Connection Error");
      } else {
        try {
          if (res.code == "token_not_valid") {
            localStorage.clear();
            history.push("/login");
          }
          setServiceRequestPending(false);
          setServiceRequestStatus(res);
        } catch (e) {
          setServiceRequestError(e);
        }
      }
    });
  }

  const [pendingTaskPending, setPendingTaskPending] = useState(true);
  const [pendingTaskError, setPendingTaskError] = useState(null);
  /**
   * Fetch Pending tasks from API
   */
  function fetchPendingTask() {
    setPendingTaskPending(true);
    setPendingTaskError("");
    setPendingTask(null);
    services.get("api/task/recent_tasks/").then((res) => {
      if (res == "TypeError: Failed to fetch") {
        setPendingTaskPending(false);
        setPendingTaskError("Connection Error");
      } else {
        try {
          if (res.code == "token_not_valid") {
            localStorage.clear();
            history.push("/login");
          }
          setPendingTaskPending(false);
          setPendingTask(res);
        } catch (e) {
          setPendingTaskError(e);
        }
      }
    });
  }

  const [callBackPending, setCallBackPending] = useState(true);
  const [callBackError, setCallBackError] = useState(null);

  /**
   * Fetch Call back requests
   */
  function fetchCallBackRequests() {
    setCallBackPending(true);
    setRecentCallError("");
    setCallBack(null);
    services.get("api/call/home_page/call_back_remainders/").then((res) => {
      if (res == "TypeError: Failed to fetch") {
        setCallBackPending(false);
        setCallBackError("Connection Error");
      } else {
        try {
          if (res.code == "token_not_valid") {
            localStorage.clear();
            history.push("/login");
          }
          setCallBackPending(false);
          setCallBack(res);
        } catch (e) {
          setCallBackError(e);
        }
      }
    });
  }

  const [fullScript, setFullScript] = useState("");
  const [fullScriptPending, setFullScriptPending] = useState(true);
  const [fullScriptError, setFullScriptError] = useState(null);
  /**
   * Fetch Full Script info
   */
  function fetchFullScriptData() {
    setFullScriptError("");
    setFullScript("");
    setFullScriptPending(true);
    services.get(`api/call_quality/manage_full_script/`).then((res) => {
      if (res == "TypeError: Failed to fetch") {
        setFullScriptPending(false);
        setFullScriptError("Connection Error");
      } else {
        try {
          setFullScript(res);
          setFullScriptPending(false);
          setFullScriptError("");
        } catch {}
      }
    });
  }
  const [nextdrop, setNextdrop] = useState("");

  const fetchnextdropdown = function () {
    let url = `api/call_quality/manage_full_script/`;
    if (product) {
      url = url + `?product_type=${product}`;
    }
    if (productType) {
      url = product
        ? url + `&product=${productType}`
        : url + `?product=${productType}`;
    }
    services.get(url).then((res) => {
      // console.log("lll", res);
      setFullScript(res);
    });
  };

  useEffect(() => {
    fetchnextdropdown();
  }, [product, productType]);

  // console.log(fullScript);

  const [verification, setVerification] = useState("");

  function fetchVerificationData() {
    services.get(`api/pcvc/verification_info/`).then((res) => {
      if (res == "TypeError: Failed to fetch") {
        console.log("failed to fethc");
      } else {
        try {
          setVerification(res);
        } catch {}
      }
    });
  }
  useEffect(() => {
    fetchVerificationData();
  }, []);

  const callInsightRedirect = function (id) {
    if (localStorage.getItem("usecase") === "Complaints Management") {
      history.push(`ticket/${id}/complaints-insight`);
    } else {
      history.push(`call/${id}/call-insight`);
    }
  };

  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />

      <section className="page-body">
        <div className="section-one clearfix">
          <div className="container-fluid">
            <Masonry
              breakpointCols={2}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              <div style={{ padding: "10px" }}>
                <div className="assistant" style={{ minHeight: "50px" }}>
                  <div style={{ paddingTop: "20px", paddingLeft: "40px" }}>
                    <label
                      style={{ fontSize: "20px", color: "#271078" }}
                      className=" flex  items-center"
                    >
                      {" "}
                      <span className=" mr-4">
                        <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                      </span>
                      {localStorage.getItem("usecase") ===
                      "Complaints Management"
                        ? "Recent Tickets"
                        : useCase === "Third Party Collection Agency"
                        ? "Recent Calls : Collections"
                        : "Recent Calls"}
                    </label>
                  </div>
                  <div style={{ minHeight: "4px", overflow: "auto" }}>
                    <div className="accordion">
                      <div className="call-table" style={{ minHeight: "10px" }}>
                        <table className="ss">
                          {localStorage.getItem("usecase") ===
                          "Complaints Management" ? (
                            <thead className="">
                              <tr>
                                <th>
                                  <p className=" text-center">Date</p>
                                </th>
                                <th>
                                  <p className=" text-center">Number</p>
                                </th>
                                <th>
                                  <p className=" text-center">Product</p>
                                </th>
                                <th>
                                  <p className=" text-center">Services</p>
                                </th>
                                <th>
                                  <p className=" text-center">Intent</p>
                                </th>
                                {/* <th>
                                  <p className=" text-center">Sentiments</p>
                                </th> */}
                                <th>
                                  <p className="">Status</p>
                                </th>
                              </tr>
                            </thead>
                          ) : useCase == "Third Party Collection Agency" ? (
                            <thead>
                              <tr>
                                <th>
                                  <p>Date</p>
                                </th>
                                <th>
                                  <p>Customer</p>
                                </th>

                                <th className=" whitespace-nowrap">
                                  <p>Call ID </p>
                                </th>
                                <th>
                                  <p>Agent</p>
                                </th>

                                <th>
                                  <p>Product</p>
                                </th>
                                <th>
                                  <p className="whitespace-nowrap">
                                    Sentiments
                                  </p>
                                </th>

                                <th>
                                  <p>Intent</p>
                                </th>
                                {/* <th>
                                  <p>Recommendations </p>
                                </th> */}
                              </tr>
                            </thead>
                          ) : (
                            <thead>
                              <tr>
                                <th>
                                  <p>Date</p>
                                </th>
                                <th>
                                  <p>Customer</p>
                                </th>
                                {localStorage.getItem("usecase") ==
                                "Insurance - PCVC Verification" ? (
                                  <th>
                                    <p>Nemp Id</p>
                                  </th>
                                ) : (
                                  ""
                                )}
                                <th>
                                  <p className=" whitespace-nowrap">
                                    {localStorage.getItem("usecase") ==
                                    "Insurance - PCVC Verification"
                                      ? "Product Type"
                                      : "Product"}{" "}
                                  </p>
                                </th>

                                <th>
                                  <p>
                                    {localStorage.getItem("usecase") ==
                                    "Insurance - PCVC Verification"
                                      ? "Product"
                                      : "Call Type"}{" "}
                                  </p>
                                </th>
                                <th>
                                  <p className="whitespace-nowrap">
                                    {localStorage.getItem("usecase") ==
                                    "Insurance - PCVC Verification"
                                      ? "Call Status"
                                      : "Sentiments"}
                                  </p>
                                </th>
                                {localStorage.getItem("usecase") !==
                                "Insurance - PCVC Verification" ? (
                                  <th>
                                    <p>Intent</p>
                                  </th>
                                ) : (
                                  ""
                                )}
                              </tr>
                            </thead>
                          )}

                          <tbody>
                            {recentCalls &&
                              recentCalls?.map((call) => (
                                <tr
                                  className="tableRow cursor-pointer"
                                  key={call._id}
                                  onClick={() => callInsightRedirect(call?._id)}
                                >
                                  <td>
                                    <a className=" whitespace-nowrap">
                                      {call?._date}
                                    </a>
                                  </td>
                                  {localStorage.getItem("usecase") ===
                                  "Complaints Management" ? (
                                    <td>{call?._ticket_number}</td>
                                  ) : (
                                    <td>
                                      {call._customer_first_name} &nbsp;{" "}
                                      {call._customer_last_name}
                                    </td>
                                  )}

                                  {localStorage.getItem("usecase") !==
                                    "Insurance - PCVC Verification" &&
                                  localStorage.getItem("usecase") !==
                                    "Complaints Management" ? (
                                    useCase ==
                                    "Third Party Collection Agency" ? (
                                      <td>
                                        {call?._id.slice(0, 5).toUpperCase()}
                                      </td>
                                    ) : (
                                      <td className=" whitespace-nowrap">
                                        {call?._product_name}
                                      </td>
                                    )
                                  ) : (
                                    <td>
                                      <a className="  text-center">
                                        {call.nemp_id || call?._product_type}
                                      </a>
                                    </td>
                                  )}
                                  {useCase ==
                                  "Third Party Collection Agency" ? (
                                    <td>
                                      <a className="  text-center">
                                        {call?._agent}
                                      </a>
                                    </td>
                                  ) : (
                                    ""
                                  )}

                                  {localStorage.getItem("usecase") ===
                                  "Complaints Management" ? (
                                    <td>{call?._product_name}</td>
                                  ) : localStorage.getItem("usecase") !==
                                      "Insurance - PCVC Verification" &&
                                    localStorage.getItem("usecase") !==
                                      "Third Party Collection Agency" ? (
                                    <td>{call._call_type}</td>
                                  ) : localStorage.getItem("usecase") ==
                                    "Third Party Collection Agency" ? (
                                    <td>{call?._product_name}</td>
                                  ) : (
                                    <td>
                                      {call?.prduct_type
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ") || " -"}
                                    </td>
                                  )}

                                  {localStorage.getItem("usecase") ===
                                  "Complaints Management" ? (
                                    <td>
                                      {call._intent > 0 ? (
                                        <p
                                          className="red"
                                          style={{ color: "green" }}
                                        >
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          className="red"
                                          style={{ color: "red" }}
                                        >
                                          <i
                                            className="fa fa-minus-circle"
                                            aria-hidden="true"
                                          />
                                        </p>
                                      )}
                                    </td>
                                  ) : useCase ==
                                    "Third Party Collection Agency" ? (
                                    <td>
                                      {Math.trunc(call?._sentiment * 100)}%
                                    </td>
                                  ) : localStorage.getItem("usecase") !==
                                    "Insurance - PCVC Verification" ? (
                                    <td>
                                      {call._sentiment > 0 ? (
                                        <p
                                          className="red"
                                          style={{ color: "green" }}
                                        >
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          className="red"
                                          style={{ color: "red" }}
                                        >
                                          <i
                                            className="fa fa-minus-circle"
                                            aria-hidden="true"
                                          />
                                        </p>
                                      )}
                                    </td>
                                  ) : (
                                    <td>
                                      <p>
                                        {call?._product_name
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ") || " -"}
                                      </p>
                                    </td>
                                  )}

                                  {localStorage.getItem("usecase") ===
                                  "Complaints Management" ? (
                                    <td>
                                      <p>{call?._ticket_status}</p>
                                    </td>
                                  ) : useCase ==
                                    "Third Party Collection Agency" ? (
                                    <td>
                                      {Math.abs(
                                        Math.trunc(call?._intent * 100)
                                      )}
                                      %
                                    </td>
                                  ) : localStorage.getItem("usecase") !==
                                    "Insurance - PCVC Verification" ? (
                                    <td>
                                      {call._intent > 0 ? (
                                        <p
                                          className="red"
                                          style={{ color: "green" }}
                                        >
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          className="red"
                                          style={{ color: "red" }}
                                        >
                                          <i
                                            className="fa fa-minus-circle"
                                            aria-hidden="true"
                                          />
                                        </p>
                                      )}
                                    </td>
                                  ) : (
                                    <td>
                                      <p
                                        className={`${
                                          call?.call_status == "non-fatal"
                                            ? "text-green-700"
                                            : "text-red-500"
                                        }`}
                                      >
                                        {(call?.call_status &&
                                          call.call_status.replace(
                                            /(?:^|\s|-)\S/g,
                                            function (a) {
                                              return a.toUpperCase();
                                            }
                                          )) ||
                                          "Fatal"}
                                      </p>
                                    </td>
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {/* {!recentCallPending && (
                          <p className=" text-[12px]  text-red-600">
                            *R,O,E,C means Resolved,Ongoing,Escalation,Closed
                          </p>
                        )} */}
                        {recentCallPending && (
                          <div style={{ textAlign: "center" }}>
                            <ClipLoader color="#2056FF" size="50px" />
                          </div>
                        )}
                        {recentCallError && (
                          <div style={{ textAlign: "center" }}>
                            <p style={{ fontSize: "25px", color: "#FF8520" }}>
                              {recentCallError === "Connection Error" && (
                                <RiSignalWifiErrorFill />
                              )}
                              {recentCallError !== "Connection Error" && (
                                <BiError />
                              )}
                              {recentCallError}
                            </p>
                          </div>
                        )}
                        {recentCalls && recentCalls.length === 0 && (
                          <div
                            style={{ fontSize: "20px", textAlign: "center" }}
                          >
                            <MdAddIcCall />
                            <p>No Records Found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {localStorage.getItem("usecase") === "Complaints Management" ? (
                <div style={{ padding: "10px" }}>
                  <div className="assistant" style={{ minHeight: "50px" }}>
                    <div style={{ paddingTop: "20px", paddingLeft: "40px" }}>
                      <label
                        style={{
                          fontSize: "20px",
                          color: "#271078",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <MdIntegrationInstructions
                          style={{ fontSize: "25px" }}
                        />
                        <span
                          className={`ml-4 flex `}
                          style={{
                            border: "1px solid lightgray",
                            marginTop: "-3px",
                          }}
                        >
                          <button
                            className={`py-1 px-6 border-r border-r-gray-400 ${
                              activetab === "Resolutions"
                                ? " bg-blue-900 text-white font-normal"
                                : ""
                            }`}
                            onClick={() => setActivetab("Resolutions")}
                          >
                            Resolutions Tips
                          </button>
                          <button
                            className={`py-1 px-6 ${
                              activetab === "Objection"
                                ? " bg-blue-900 text-white font-normal"
                                : ""
                            }`}
                            // style={{ border: "1px solid red" }}
                            onClick={() => setActivetab("Objection")}
                          >
                            Objection Handling
                          </button>
                        </span>
                      </label>
                    </div>

                    <div
                      style={{ height: "350px", overflow: "auto" }}
                      className=" scroll-design "
                    >
                      <div className="accordion">
                        <div
                          className="call-table"
                          style={{ minHeight: "10px" }}
                        >
                          {!fullScriptPending && fullScriptError === "" && (
                            <div className="view-list">
                              <ul
                                className="clearfix"
                                // style={{ paddingTop: "20px" }}
                              >
                                <div
                                  style={{
                                    padding: "5px",
                                    fontSize: "18px",
                                    color: "black",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {activetab == "Resolutions" &&
                                  fullScript.length ? (
                                    fullScript.map((e, i) => (
                                      <div
                                        className={`flex  mt-4 rounded-lg  p-4`}
                                        style={{
                                          border: "1px solid lightgray",

                                          // alignItems: "center",
                                        }}
                                      >
                                        {/* <input type="checkbox" /> */}
                                        <span className=" ">
                                          <div
                                            className=" flex mb-2 py-3 px-4 rounded-lg"
                                            style={{
                                              // border: "1px solid red",
                                              background: "#eef2f5",
                                            }}
                                          >
                                            {localStorage.getItem("usecase") ==
                                            "Insurance - PCVC Verification" ? (
                                              <>
                                                <div className="text-2xl  ">
                                                  <span className="">
                                                    {e?._product_Type
                                                      .toLowerCase()
                                                      .split(" ")
                                                      .map(
                                                        (word) =>
                                                          word
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                          word.slice(1)
                                                      )
                                                      .join(" ")}
                                                  </span>
                                                </div>{" "}
                                                <div className=" text-2xl ml-1 mr-1">
                                                  :
                                                </div>
                                                <div className="text-2xl">
                                                  <span className="">
                                                    {e?._product
                                                      .toLowerCase()
                                                      .split(" ")
                                                      .map(
                                                        (word) =>
                                                          word
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                          word.slice(1)
                                                      )
                                                      .join(" ")}
                                                  </span>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className="text-2xl ">
                                                  Title :
                                                  <span className="">
                                                    {e?._title}
                                                  </span>
                                                </div>{" "}
                                              </>
                                            )}
                                          </div>
                                          <p
                                            className=""
                                            style={{ fontSize: "16px" }}
                                            dangerouslySetInnerHTML={{
                                              __html: e?._script.replace(
                                                /\n/g,
                                                "<br />"
                                              ),
                                            }}
                                          />
                                        </span>
                                      </div>
                                    ))
                                  ) : (
                                    <div className=" flex px-12">
                                      <ul className=" list-disc">
                                        <li>
                                          Can you confirm that your issue has
                                          been fully resolved?
                                        </li>
                                        <li>
                                          Are there any remaining questions or
                                          concerns you'd like us to address?
                                        </li>
                                        <li>
                                          Are you comfortable with us closing
                                          this ticket now?
                                        </li>
                                        <li>
                                          Is there any further assistance you
                                          need from us at this time?
                                        </li>
                                        {/* <li>Lorem, ipsum.</li> */}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </ul>
                            </div>
                          )}
                          {fullScriptPending && (
                            <div style={{ textAlign: "center" }}>
                              <ClipLoader color="#2056FF" size="50px" />
                            </div>
                          )}
                          {setFullScriptError === "" && (
                            <div style={{ textAlign: "center" }}>
                              <p style={{ fontSize: "25px", color: "#FF8520" }}>
                                {fullScriptError === "Connection Error" && (
                                  <RiSignalWifiErrorFill />
                                )}
                                {fullScriptError !== "Connection Error" && (
                                  <BiError />
                                )}
                                {fullScriptError}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ padding: "10px" }}>
                  <div className="assistant" style={{ minHeight: "50px" }}>
                    <div style={{ paddingTop: "20px", paddingLeft: "40px" }}>
                      <label
                        style={{
                          fontSize: "20px",
                          color: "#271078",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <MdIntegrationInstructions
                          style={{ fontSize: "25px" }}
                        />
                        <span className="ml-4" style={{ marginTop: "-3px" }}>
                          {useCase == "Third Party Collection Agency"
                            ? "Collection Script"
                            : "Call Script"}
                        </span>
                      </label>
                    </div>
                    {localStorage.getItem("usecase") ==
                    "Insurance - PCVC Verification" ? (
                      <div className="mt-4" style={{ marginLeft: "40px" }}>
                        <div className=" w-1/2 flex  ">
                          <select
                            onChange={(e) => setProduct(e.target.value)}
                            style={{
                              borderBottom: "1px solid lightgray",
                              marginRight: "15px",
                            }}
                            value={product}
                          >
                            <option value="" selected disabled>
                              Select Product Type
                            </option>
                            {productDetail &&
                              productDetail?.call_type.map((product) => (
                                <option
                                  key={product.title}
                                  value={product.title}
                                >
                                  {product.title
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </option>
                              ))}
                          </select>

                          <select
                            onChange={(e) => setProductType(e.target.value)}
                            style={{ borderBottom: "1px solid lightgray" }}
                            value={productType}
                          >
                            <option value="" selected disabled>
                              Select Product
                            </option>

                            {productDetail &&
                              fullScript &&
                              fullScript?.map((product) => (
                                <option
                                  key={product?._product}
                                  value={product?._product}
                                >
                                  {product?._product
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </option>
                              ))}
                          </select>
                          {/* <select name="" id="">
                          {nextdrop?.map((e) => (
                            <option>{e?._product}</option>
                          ))}
                        </select> */}

                          <button
                            className="mx-4 bg-blue-900 px-3 text-white  "
                            // style={{ border: "1px solid grey" }}
                            onClick={() => {
                              setNextdrop("");
                              setProduct("");
                              setProductType("");
                            }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      style={{ height: "350px", overflow: "auto" }}
                      className=" scroll-design "
                    >
                      <div className="accordion">
                        <div
                          className="call-table"
                          style={{ minHeight: "10px" }}
                        >
                          {!fullScriptPending && fullScriptError === "" && (
                            <div className="view-list">
                              <ul
                                className="clearfix"
                                // style={{ paddingTop: "20px" }}
                              >
                                <div
                                  style={{
                                    padding: "5px",
                                    fontSize: "18px",
                                    color: "black",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {fullScript.length ? (
                                    fullScript.map((e, i) => (
                                      <div
                                        className={`flex  mt-4 rounded-lg  p-4
       
        `}
                                        style={{
                                          border: "1px solid lightgray",

                                          // alignItems: "center",
                                        }}
                                      >
                                        {/* <input type="checkbox" /> */}
                                        <span className=" ">
                                          <div
                                            className=" flex mb-2 py-3 px-4 rounded-lg"
                                            style={{
                                              // border: "1px solid red",
                                              background: "#eef2f5",
                                            }}
                                          >
                                            {localStorage.getItem("usecase") ==
                                            "Insurance - PCVC Verification" ? (
                                              <>
                                                <div className="text-2xl  ">
                                                  <span className="">
                                                    {e?._product_Type
                                                      .toLowerCase()
                                                      .split(" ")
                                                      .map(
                                                        (word) =>
                                                          word
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                          word.slice(1)
                                                      )
                                                      .join(" ")}
                                                  </span>
                                                </div>{" "}
                                                <div className=" text-2xl ml-1 mr-1">
                                                  :
                                                </div>
                                                <div className="text-2xl">
                                                  <span className="">
                                                    {e?._product
                                                      .toLowerCase()
                                                      .split(" ")
                                                      .map(
                                                        (word) =>
                                                          word
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                          word.slice(1)
                                                      )
                                                      .join(" ")}
                                                  </span>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className="text-2xl ">
                                                  Title :
                                                  <span className="">
                                                    {e?._title}
                                                  </span>
                                                </div>{" "}
                                              </>
                                            )}
                                          </div>
                                          <p
                                            className=""
                                            style={{ fontSize: "16px" }}
                                            dangerouslySetInnerHTML={{
                                              __html: e?._script.replace(
                                                /\n/g,
                                                "<br />"
                                              ),
                                            }}
                                          />
                                        </span>
                                      </div>
                                    ))
                                  ) : (
                                    <div className=" flex justify-center items-center">
                                      <p>No Scripts Found</p>
                                    </div>
                                  )}
                                </div>
                              </ul>
                            </div>
                          )}
                          {fullScriptPending && (
                            <div style={{ textAlign: "center" }}>
                              <ClipLoader color="#2056FF" size="50px" />
                            </div>
                          )}
                          {setFullScriptError === "" && (
                            <div style={{ textAlign: "center" }}>
                              <p style={{ fontSize: "25px", color: "#FF8520" }}>
                                {fullScriptError === "Connection Error" && (
                                  <RiSignalWifiErrorFill />
                                )}
                                {fullScriptError !== "Connection Error" && (
                                  <BiError />
                                )}
                                {fullScriptError}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ padding: "10px" }}>
                <div className="assistant" style={{ minHeight: "50px" }}>
                  <div style={{ paddingTop: "20px", paddingLeft: "40px" }}>
                    <label style={{ fontSize: "20px", color: "#271078" }}>
                      {" "}
                      <i className="fa fa-tasks" aria-hidden="true"></i>{" "}
                      &nbsp;Pending Tasks
                    </label>
                  </div>
                  <div
                    style={{
                      paddingBottom: "10px",
                      minHeight: "40px",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <div className="accordion">
                      {pendingTask &&
                        pendingTask.map((task) => (
                          <div
                            key={task.id}
                            className="row dataRow"
                            style={{
                              paddingLeft: "25px",
                              paddingTop: "10px",
                              paddingRight: "25px",
                            }}
                          >
                            <div className="col-md-1">
                              <div
                                className="star"
                                style={{ background: "green" }}
                              >
                                <i
                                  className="fa fa-tasks"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>

                            <div className="col-md-5">
                              <div className="sat-content">
                                <h3>{task.title}</h3>
                                <label>{task.priority} Priority</label>
                              </div>
                            </div>

                            <div
                              className="col-md-3"
                              style={{ fontSize: "16px" }}
                            >
                              <i
                                style={{ color: "green" }}
                                className="fa fa-user"
                                aria-hidden="true"
                              ></i>{" "}
                              &nbsp;
                              <a style={{ color: "black" }}>
                                {task.created_by__first_name} &nbsp;{" "}
                                {task.created_by__last_name}
                              </a>
                            </div>

                            <div
                              className="col-md-3"
                              style={{ fontSize: "16px" }}
                            >
                              <i
                                style={{ color: "red" }}
                                className="fa fa-calendar-check-o"
                                aria-hidden="true"
                              ></i>{" "}
                              &nbsp;
                              <a style={{ color: "black" }}>
                                {task.creation_date.substring(0, 10)}
                              </a>
                            </div>
                          </div>
                        ))}

                      {pendingTaskPending && (
                        <div style={{ textAlign: "center" }}>
                          <ClipLoader color="#2056FF" size="50px" />
                        </div>
                      )}
                      {pendingTaskError && (
                        <div style={{ textAlign: "center" }}>
                          <p style={{ fontSize: "25px", color: "#FF8520" }}>
                            {pendingTaskError === "Connection Error" && (
                              <RiSignalWifiErrorFill />
                            )}
                            {pendingTaskError !== "Connection Error" && (
                              <BiError />
                            )}
                            {pendingTaskError}
                          </p>
                        </div>
                      )}
                      {pendingTask && pendingTask.length === 0 && (
                        <div style={{ fontSize: "20px", textAlign: "center" }}>
                          <BiTaskX />
                          <p>No Pending Task </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ padding: "10px" }}>
                <div className="assistant" style={{ minHeight: "50px" }}>
                  <div style={{ paddingTop: "20px", paddingLeft: "40px" }}>
                    <label style={{ fontSize: "20px", color: "#271078" }}>
                      {" "}
                      <i className="fa fa-bell" aria-hidden="true"></i>{" "}
                      &nbsp;Callback Reminders
                    </label>
                  </div>
                  <div
                    style={{
                      paddingBottom: "10px",
                      minHeight: "40px",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <div style={{ paddingLeft: "25px", paddingRight: "25px" }}>
                      {callBack &&
                        callBack.map((cb) => (
                          <div
                            className="row dataRow"
                            style={{ padding: "5px" }}
                          >
                            <div className="col-md-1">
                              <div
                                className="star"
                                style={{ background: "blue" }}
                              >
                                <i
                                  className="fa fa-bell"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>

                            <div
                              className="col-md-3"
                              style={{ fontSize: "16px" }}
                            >
                              <p style={{ color: "black" }}>
                                {" "}
                                <i
                                  style={{ color: "green" }}
                                  className="fa fa-user"
                                  aria-hidden="true"
                                ></i>{" "}
                                &nbsp;{cb.customer__first_name}&nbsp;
                                {cb.customer__last_name}
                              </p>
                              <label>{cb.customer__contact_number}</label>
                            </div>
                            {/* <div className="col-md-1">
                              <div
                                className="star"
                                style={{ background: "blue" }}
                              >
                                hy
                              </div>
                            </div> */}

                            <div
                              className="col-md-3"
                              style={{ fontSize: "16px" }}
                            >
                              <label>{cb.product__title}</label>
                              {/* reason */}
                              Busy with Office work
                            </div>

                            <div
                              className="col-md-3"
                              style={{ fontSize: "16px" }}
                            >
                              <i
                                style={{ color: "red" }}
                                className="fa fa-calendar-check-o"
                                aria-hidden="true"
                              ></i>{" "}
                              &nbsp;
                              <a style={{ color: "black" }}>{cb.date__date}</a>
                            </div>

                            <div
                              className="col-md-2"
                              style={{ fontSize: "16px" }}
                            >
                              <i
                                style={{ color: "red" }}
                                className="fa fa-clock-o"
                                aria-hidden="true"
                              ></i>{" "}
                              &nbsp;
                              <a style={{ color: "black" }}>{cb.date__time}</a>
                            </div>
                          </div>
                        ))}
                    </div>

                    {callBackPending && (
                      <div style={{ textAlign: "center" }}>
                        <ClipLoader color="#2056FF" size="50px" />
                      </div>
                    )}
                    {callBackError && (
                      <div style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "25px", color: "#FF8520" }}>
                          {callBackError === "Connection Error" && (
                            <RiSignalWifiErrorFill />
                          )}
                          {callBackError !== "Connection Error" && <BiError />}
                          {callBackError}
                        </p>
                      </div>
                    )}
                    {callBack && callBack.length === 0 && (
                      <div style={{ fontSize: "20px", textAlign: "center" }}>
                        <BiCommentAdd />
                        <p>No Records Found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div style={{ padding: "10px" }}>
                <div className="assistant" style={{ minHeight: "50px" }}>
                  <div style={{ paddingTop: "20px", paddingLeft: "40px" }}>
                    <label style={{ fontSize: "20px", color: "#271078" }}>
                      {" "}
                      <i className="fa fa-comment" aria-hidden="true"></i>&nbsp;
                      Manager Comment
                    </label>
                  </div>
                  <div
                    className=""
                    style={{
                      paddingBottom: "10px",
                      minHeight: "40px",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <div style={{ paddingLeft: "25px", paddingRight: "25px" }}>
                      {managerComment &&
                        managerComment.map((comment) => (
                          <div
                            key={comment.id}
                            className="row dataRow"
                            style={{
                              paddingLeft: "5px",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              paddingRight: "5px",
                            }}
                          >
                            <div className="col-md-1">
                              <div className="star">
                                <i
                                  className="fa fa-commenting"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="sat-content">
                                <h3>
                                  {comment.comment
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </h3>
                                <label>
                                  {comment.commented_by__first_name}&nbsp;
                                  {comment.commented_by__last_name}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <i
                                className="fa fa-calendar"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              <a style={{ fontSize: "15px" }}>
                                {comment.date__date}
                              </a>
                              <br />
                              <i
                                className="fa fa-phone-square"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              <a style={{ fontSize: "15px" }}>
                                {comment.call__call_reference}
                              </a>
                            </div>
                            {/* <div className="col-md-1">
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                              ></i>
                            </div> */}
                          </div>
                        ))}
                    </div>
                    {managerCommentPending && (
                      <div style={{ textAlign: "center" }}>
                        <ClipLoader color="#2056FF" size="50px" />
                      </div>
                    )}
                    {managerCommentError && (
                      <div style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "25px", color: "#FF8520" }}>
                          {managerCommentError === "Connection Error" && (
                            <RiSignalWifiErrorFill />
                          )}
                          {managerCommentError !== "Connection Error" && (
                            <BiError />
                          )}
                          {managerCommentError}
                        </p>
                      </div>
                    )}
                    {managerComment && managerComment.length === 0 && (
                      <div style={{ fontSize: "20px", textAlign: "center" }}>
                        <BiCommentAdd />
                        <p>No Records Found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div style={{ padding: "10px" }}>
                <div className="assistant" style={{ minHeight: "50px" }}>
                  <div style={{ paddingTop: "20px", paddingLeft: "40px" }}>
                    <label style={{ fontSize: "20px", color: "#271078" }}>
                      {" "}
                      <i
                        className="fa fa-snowflake-o"
                        aria-hidden="true"
                      ></i>{" "}
                      {localStorage.getItem("usecase") ===
                      "Complaints Management"
                        ? "Complaints Resolution Score Card"
                        : useCase === "Third Party Collection Agency"
                        ? "Collections Status"
                        : localStorage.getItem("usecase") !==
                          "Insurance - PCVC Verification"
                        ? "Opportunity Status"
                        : "Verification Status"}
                    </label>
                  </div>
                  <div style={{ minHeight: "40px", overflow: "auto" }}>
                    <div className="accordion">
                      <div className="call-table" style={{ minHeight: "10px" }}>
                        {localStorage.getItem("usecase") !==
                        "Insurance - PCVC Verification" ? (
                          <table className="ss">
                            <thead>
                              <tr>
                                <th>
                                  <p>Reference</p>
                                </th>
                                <th>
                                  <p>Customer</p>
                                </th>
                                <th>
                                  <p>Product</p>
                                </th>
                                <th>
                                  <p>Status</p>
                                </th>
                                {localStorage.getItem("usecase") !==
                                "Insurance - PCVC Verification" ? (
                                  <th>
                                    <p>Sentiments</p>
                                  </th>
                                ) : (
                                  <th>
                                    <p>-</p>
                                  </th>
                                )}
                              </tr>
                            </thead>

                            <tbody>
                              {leadStatus &&
                                leadStatus.map((lead) => (
                                  <tr key={lead._id} className="tableRow">
                                    <td>
                                      <a>{lead.call__call_reference}</a>
                                    </td>
                                    <td>
                                      {lead.call__customer__first_name}&nbsp;
                                      {lead.call__customer__last_name}
                                    </td>
                                    <td>
                                      <a>{lead.product__title}</a>
                                    </td>
                                    <td>{lead.review_status}</td>
                                    <td>
                                      {lead.call__customer_sentiment > 0 ? (
                                        <p
                                          className="red"
                                          style={{ color: "green" }}
                                        >
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          className="red"
                                          style={{ color: "red" }}
                                        >
                                          <i
                                            className="fa fa-minus-circle"
                                            aria-hidden="true"
                                          />
                                        </p>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <table className="ss">
                            <thead>
                              <tr>
                                <th>
                                  <p>Date</p>
                                </th>
                                <th>
                                  <p>Customer</p>
                                </th>
                                <th>
                                  <p>NEMP Id</p>
                                </th>
                                <th>
                                  <p>Product</p>
                                </th>

                                <th>
                                  <p>Status</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {verification &&
                                verification.map((lead) => (
                                  <tr key={lead._id} className="tableRow">
                                    <td>
                                      <a>{lead?.call_date}</a>
                                    </td>
                                    <td>{lead?.customer}</td>
                                    <td>
                                      <a>{lead?.nemp_id}</a>
                                    </td>
                                    <td>
                                      {lead.product
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")}
                                    </td>
                                    <td>
                                      {lead?.call_status[0].toUpperCase() +
                                        lead?.call_status.slice(1)}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                      {opportunityPending && (
                        <div style={{ textAlign: "center" }}>
                          <ClipLoader color="#2056FF" size="50px" />
                        </div>
                      )}
                      {opportunityError && (
                        <div style={{ textAlign: "center" }}>
                          <p style={{ fontSize: "25px", color: "#FF8520" }}>
                            {opportunityError === "Connection Error" && (
                              <RiSignalWifiErrorFill />
                            )}
                            {opportunityError !== "Connection Error" && (
                              <BiError />
                            )}
                            {opportunityError}
                          </p>
                        </div>
                      )}
                      {leadStatus && leadStatus.length === 0 && (
                        <div style={{ fontSize: "20px", textAlign: "center" }}>
                          <VscGitPullRequestCreate />
                          <p>No Records Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {localStorage.getItem("usecase") !==
                "Insurance - PCVC Verification" &&
              localStorage.getItem("usecase") !== "Complaints Management" ? (
                <div style={{ padding: "10px" }}>
                  <div className="assistant" style={{ minHeight: "50px" }}>
                    <div style={{ paddingTop: "20px", paddingLeft: "40px" }}>
                      <label style={{ fontSize: "20px", color: "#271078" }}>
                        {" "}
                        <i
                          className="fa fa-superpowers"
                          aria-hidden="true"
                        ></i>{" "}
                        &nbsp;Service Request Status
                      </label>
                    </div>
                    <div style={{ minHeight: "40px", overflow: "auto" }}>
                      <div className="accordion">
                        <div
                          className="call-table"
                          style={{ minHeight: "10px" }}
                        >
                          <table className="ss">
                            <thead>
                              <tr>
                                <th>
                                  <p>Reference</p>
                                </th>
                                <th>
                                  <p>Customer</p>
                                </th>
                                <th>
                                  <p>Product</p>
                                </th>
                                <th>
                                  <p>Status</p>
                                </th>
                                <th>
                                  <p>Sentiments</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {serviceRequestStatus &&
                                serviceRequestStatus.map((sr) => (
                                  <tr key={sr._id} className="tableRow">
                                    <td>
                                      <a>{sr.call__call_reference}</a>
                                    </td>
                                    <td>
                                      {sr.call__customer__first_name}&nbsp;
                                      {sr.call__customer__last_name}
                                    </td>
                                    <td>
                                      <a>{sr.product__title}</a>
                                    </td>
                                    <td>{sr.review_status}</td>
                                    <td>
                                      {sr.call__customer_sentiment > 0 ? (
                                        <p
                                          className="red"
                                          style={{ color: "green" }}
                                        >
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          className="red"
                                          style={{ color: "red" }}
                                        >
                                          <i
                                            className="fa fa-minus-circle"
                                            aria-hidden="true"
                                          />
                                        </p>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {serviceRequestPending && (
                          <div style={{ textAlign: "center" }}>
                            <ClipLoader color="#2056FF" size="50px" />
                          </div>
                        )}
                        {serviceRequestError && (
                          <div style={{ textAlign: "center" }}>
                            <p style={{ fontSize: "25px", color: "#FF8520" }}>
                              {serviceRequestError === "Connection Error" && (
                                <RiSignalWifiErrorFill />
                              )}
                              {serviceRequestError !== "Connection Error" && (
                                <BiError />
                              )}
                              {serviceRequestError}
                            </p>
                          </div>
                        )}
                        {serviceRequestStatus &&
                          serviceRequestStatus.length === 0 && (
                            <div
                              style={{ fontSize: "20px", textAlign: "center" }}
                            >
                              <VscGitPullRequestCreate />
                              <p>No Records Found</p>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {localStorage.getItem("usecase") !==
              "Insurance - PCVC Verification" ? (
                <div style={{ padding: "10px" }}>
                  <div className="assistant" style={{ height: "450px" }}>
                    <div style={{ paddingTop: "20px", paddingLeft: "40px" }}>
                      <label style={{ fontSize: "20px", color: "#271078" }}>
                        {" "}
                        <i
                          className="fa fa-area-chart"
                          aria-hidden="true"
                        ></i>{" "}
                        {localStorage.getItem("usecase") ===
                        "Complaints Management"
                          ? "Complaints Intent Trends"
                          : useCase === "Third Party Collection Agency"
                          ? "Collection Score Trends "
                          : "CX Score Trend"}{" "}
                        &nbsp;
                      </label>
                    </div>
                    <div
                      style={{
                        height: "390px",
                        overflow: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      <div className="accordion">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={cxTrendOptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {localStorage.getItem("usecase") !==
              "Insurance - PCVC Verification" ? (
                <div style={{ padding: "10px" }}>
                  <div className="assistant" style={{ height: "450px" }}>
                    <div style={{ paddingTop: "20px", paddingLeft: "40px" }}>
                      <label style={{ fontSize: "20px", color: "#271078" }}>
                        {" "}
                        <i
                          className="fa fa-bar-chart"
                          aria-hidden="true"
                        ></i>{" "}
                        {localStorage.getItem("usecase") ===
                        "Complaints Management"
                          ? "Complaints Intent Analysis"
                          : useCase === "Third Party Collection Agency"
                          ? "Collections : Intent Analysis"
                          : "Intent Analysis"}{" "}
                        &nbsp;
                      </label>
                    </div>
                    <div
                      style={{
                        height: "390px",
                        overflow: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      <div className="accordion">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={aggregatedCustomerIntentOptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Masonry>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
