import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import NavBar from "../nav/nav-bar";
import ReportNav from "./report-nav";
import { useState } from "react";
// import axios from "axios";
import Service from "../webservice/http";
import { useEffect } from "react";
import ForexViewmore from "./forexViewmore";
import Pagination from "../components/pagination";
import { useHistory } from "react-router-dom";
import axios from "axios";
import config from "../config";

function Forex() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  // const [open1, setopen1] = useState(false);
  const [totalpage, setTotalpage] = useState([]);
  const [totalresult, setTotalresult] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [data, setData] = useState();
  const [error, setError] = useState();
  const services = new Service();
  const [id, setid] = useState("");
  function fetchData() {
    services.get("api/report/forex_report_extraction/").then((res) => {
      // console.log(res);
      setData(res);
      setTotalpage(res?.pagination?.page_numbers);
      setTotalresult(res?.pagination?.total_results);
    });
  }
  // console.log(data);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    history.push(`/call-list?page=${pageNumber}`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(data);
  const styles = {
    redIcon: {
      float: "inherit",
      color: "red",
    },
    greenIcon: {
      float: "inherit",
      color: "green",
    },
    alignTextCenter: {
      textAlign: "center",
    },
  };
  const downloadReport = async function () {
    let downloadUrl = `${config.API_BASE_URL}/api/report/download_forex_report/`;

    // if (range.length == 2) {
    //   downloadUrl = downloadUrl + `?from_date=${range[0]}&to_date=${range[1]}`;
    // }
    // if (search) {
    //   downloadUrl =
    //     range.length == 2
    //       ? downloadUrl + `&search=${search}`
    //       : downloadUrl + `?search=${search}`;
    // }
    // if (_product_name) {
    //   downloadUrl =
    //     range.length == 2 || search
    //       ? downloadUrl + `&product=${_product_name}`
    //       : downloadUrl + `?product=${_product_name}`;
    // }
    // if (agent) {
    //   downloadUrl =
    //     range.length == 2 || search || _product_name
    //       ? downloadUrl + `&agent=${agent}`
    //       : downloadUrl + `?agent=${agent}`;
    // }
    // if (language) {
    //   downloadUrl =
    //     range.length == 2 || search || _product_name || agent
    //       ? downloadUrl + `&language=${language}`
    //       : downloadUrl + `?language=${language}`;
    // }
    // if (lead) {
    //   downloadUrl =
    //     range.length == 2 || search || _product_name || agent
    //       ? downloadUrl + `&team_lead=${lead}`
    //       : downloadUrl + `?team_lead=${lead}`;
    // }
    try {
      const response = await axios.get(downloadUrl, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // console.log(url);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dashboard_data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />
      <div>
        <div className="container-fluid">
          <div className="my-call">
            <div
              className="side-panel-with-table clearfix"
              style={{ display: "flex" }}
            >
              <ReportNav />
              <div className="call-table">
                <div className="my-calls-column">
                  <div className="calls-top-pannel">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="pannel-heading clearfix">
                          <div className="pannel-heading-icon">
                            <i
                              className="fa fa-snowflake-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="pannel-heading-info">
                            <h3>Forex </h3>
                            <p>Details</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" flex justify-end m-6">
                  <button
                    className={` text-white px-8 py-4 rounded-xl export-btn tracking-wide ${
                      !data?.data?.length ? " opacity-60" : ""
                    }`}
                    disabled={!data?.data?.length}
                    onClick={downloadReport}
                  >
                    Export
                  </button>
                </div>
                <table className="ss">
                  <thead>
                    <tr>
                      <th>
                        <p>Name</p>
                      </th>
                      <th>
                        <p>Contract Number</p>
                      </th>
                      {/* <th>
                        <p>Product</p>
                      </th> */}
                      <th>
                        <p>Buy</p>
                      </th>
                      <th>
                        <p>Sell</p>
                      </th>
                      <th>
                        <p>Quantity</p>
                      </th>

                      <th>
                        <p>Final Price </p>
                      </th>

                      <th>
                        <p>Amount</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((e, i) => (
                      <tr
                        // onClick={() => {
                        //   setOpen(true);
                        //   setid(e?.id);
                        // }}
                        // className="p-up"
                        key={i}
                      >
                        <td>
                          <p className="blue">{e?.customer_name}</p>
                        </td>
                        <td>
                          <p>{e?.contract_number}</p>
                        </td>
                        {/* <td>
                        <p className="blue">Forex</p>
                      </td> */}
                        <td>
                          <p>{e?.buy}</p>
                        </td>
                        <td style={{ alignItems: "center" }}>
                          <p>{e?.sell}</p>
                        </td>
                        <td>
                          <p>{e?.quantity}</p>
                        </td>
                        <td>
                          <p>{e?.final_price}</p>
                        </td>
                        <td
                          style={{ alignItems: "center", whiteSpace: "nowrap" }}
                        >
                          <p>{e?.amount}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalpage.length}
                  onPageChange={handlePageClick}
                  totalresult={totalresult}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* {open && <ForexViewmore orderId={id} setOpen={setOpen} />} */}
    </div>
  );
}

export default Forex;
