import React, { useEffect, useState } from "react";
import Service from "./webservice/http";
import { useHistory } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { useSelector, useDispatch } from "react-redux";
import { makeVanish } from "./features/sidebar/sidebar";

const Profiling = (props) => {
  const history = useHistory();
  const services = new Service();
  const dispatch = useDispatch();

  return (
    <>
      <div
        className="filterDiv"
        style={{
          height: "100%",
          width: props.width,
          position: "fixed",
          zIndex: "100",
          top: "0",
          right: "0",
          backgroundColor: "#DEE3E8",
          overflowX: "hidden",
          // transition: "0.5s",
          // paddingTop: "60px",
          // borderLeftStyle: "solid",
          // borderLeftColor: "orange",
          // borderLeftWidth: "3px"
        }}
      >
        <span className=" flex justify-between align-middle">
          <div className=" ml-6 mt-8 flex items-center ">
            <div className="pannel-heading-icon  ">
              <i className="flaticon-incoming-call"></i>
            </div>
            {localStorage.getItem("usecase") === "Complaints Management" ? (
              <div
                className=" font-bold text-4xl"
                style={{ marginLeft: "10px" }}
              >
                Ticket History
              </div>
            ) : (
              <div
                className=" font-bold text-4xl"
                style={{ marginLeft: "10px" }}
              >
                Caller History
              </div>
            )}
          </div>

          <div
            className=" text-3xl  mr-3"
            style={{ marginTop: "7px" }}
            onClick={() => {
              props.changeSideBarWidth();
              dispatch(makeVanish());
            }}
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </span>
        <div style={{ padding: "4px 4px 4px 4px" }}>
          <div className=" bg-white p-6 mt-6">
            <div className=" flex  ">
              <span>
                <span className=" font-bold text-2xl whitespace-nowrap">
                  Name :{" "}
                </span>
                Mr Mark{" "}
              </span>
              <span>
                <span
                  className=" font-bold text-2xl"
                  style={{ marginLeft: "137px" }}
                >
                  Customer ID :{" "}
                </span>{" "}
                124386
              </span>
            </div>
            {/* <span>  / </span> */}
            {/* <span>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde,
              voluptatibus.
            </span> */}
          </div>
          <div
            className=" font-bold text-4xl mt-6"
            style={{ marginLeft: "10px" }}
          >
            {localStorage.getItem("usecase") === "Complaints Management"
              ? "Recent Tickets :"
              : " Recent Calls :"}
          </div>
          <div className=" bg-white p-6 mt-4">
            <Table striped="columns">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Agent</th>
                  <th>Product</th>
                  <th>Services</th>
                  <th>Intent Score</th>
                </tr>
              </thead>
              {localStorage.getItem("usecase") === "Complaints Management" ? (
                <tbody>
                  <tr>
                    <td className=" whitespace-nowrap">18-10-2023</td>
                    <td>Ravi</td>
                    <td>Credit card</td>
                    <td>-</td>
                    <td>51</td>
                  </tr>
                  <tr>
                    <td className=" whitespace-nowrap">21-3-2023</td>
                    <td>Alex</td>
                    <td>Debit Card</td>
                    <td>-</td>
                    <td>61</td>
                  </tr>
                  <tr>
                    <td className=" whitespace-nowrap">11-1-2023</td>
                    <td>David</td>
                    <td>-</td>
                    <td>EMI Complaints</td>
                    <td>71</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>18-10-2023</td>
                    <td>Ravi</td>
                    <td>Forex</td>
                    <td>Service Request</td>
                    <td>51</td>
                  </tr>
                  <tr>
                    <td>21-3-2023</td>
                    <td>Alex</td>
                    <td>Equity</td>
                    <td>Opportunity</td>
                    <td>61</td>
                  </tr>
                  <tr>
                    <td>11-1-2023</td>
                    <td>David</td>
                    <td>Equity</td>
                    <td>Collection</td>
                    <td>71</td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>

          <div
            className=" font-bold text-4xl mt-6 mb-4"
            style={{ marginLeft: "10px" }}
          >
            Summary :{" "}
          </div>
          <div className=" bg-white p-6">
            <div className="mt-4 ">
              <span className=" font-bold text-2xl">Aggregated Intent : </span>
              <span>65%</span>
            </div>
            <div className="mt-8">
              <span className=" font-bold text-2xl">
                Aggregated Sentiment :{" "}
              </span>
              <span> 42%</span>
            </div>
            <div className="mt-8">
              {localStorage.getItem("usecase") === "Complaints Management" ? (
                <span className=" font-bold text-2xl">
                  Complaints Intent Trends :{" "}
                </span>
              ) : (
                <span className=" font-bold text-2xl">CX score : </span>
              )}
              {/* <span>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde,
              voluptatibus.
            </span> */}
              <span> 42%</span>
            </div>
          </div>
          <div
            className=" font-bold text-4xl mt-6 mb-4"
            style={{ marginLeft: "10px" }}
          >
            Consumable Insights :{" "}
          </div>
          <div className=" bg-white p-6 mb-6">
            <div className="mt-4 ">
              <span className=" font-bold text-2xl">Recommendation : </span>
              {localStorage.getItem("usecase") === "Complaints Management" ? (
                <span className=" text-red-500">
                  EMI plan offers can be proposed as the customer uses Credit
                  card frequently
                </span>
              ) : (
                <span className=" text-red-500">
                  Mostly into converting currencies , can offer the customer to
                  buy some shares.
                </span>
              )}
            </div>
            <div className="mt-8">
              <span className=" font-bold text-2xl">Last Interaction: </span>
              <span className="">Credit Card enquiry</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profiling;
