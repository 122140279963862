import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Header from "../../header/header";
import NavBar from "../../nav/nav-bar";
import Footer from "../../footer/footer";
import { NotificationContainer } from "react-notifications";
import ReportNav from "../report-nav";
import detail from "../../assets/images/detail.svg";
import page from "../../assets/images/page.svg";
import exportIcon from "../../assets/images/export.svg";
import callReport from "../../assets/images/call-report.svg";
import customer from "../../assets/images/customer.svg";
import process from "../../assets/images/process.svg";
import callClosing from "../../assets/images/call-closing.svg";
import service from "../../assets/images/service.svg";
import documentIcon from "../../assets/images/document.svg";
import call from "../../assets/images/call.svg";
import serviceIcon from "../../assets/images/service.svg";
import score from "../../assets/images/score.svg";
import scoreBlack from "../../assets/images/score2.svg";
import Service from "../../webservice/http";
import ClipLoader from "react-spinners/ClipLoader";

function Detailedreport() {
  const { id } = useParams();
  const service = new Service();
  const [data, setData] = useState("");
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const res = service.get(`api/call_verification/predue_dasboard/${id}/`);
    res.then((response) => {
      // console.log(response);
      setData(response);
      setLoading(false);
    });
  }, []);

  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />
      {Loading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "100vh" }}
        >
          {" "}
          <ClipLoader color="#2056FF" size="50px" />
        </div>
      ) : (
        <div>
          <div className="container-fluid">
            <div
              className="my-call flex flex-col gap-4"
              style={{ background: "none" }}
            >
              <div className="flex items-center bg-white p-4 rounded-xl gap-6">
                <img src={detail} alt="detail" />
                <span
                  className="text-[#484848] font-bold"
                  style={{ color: "#484848", fontSize: "18px" }}
                >
                  Detailed Report <br />
                  Customer: ( Ref# :
                  {`${id.slice(0, 8).toString().toUpperCase()}`} )
                </span>
              </div>
              <div className="flex items-center justify-between bg-white px-4 py-4 rounded-xl">
                <span className=" flex gap-4">
                  <img src={page} alt="detail" />
                  <span
                    className="font-bold"
                    style={{ color: "#271078", fontSize: "16px" }}
                  >
                    Details
                  </span>
                </span>
                <button
                  className="p-3  text-black rounded-lg flex items-center gap-4"
                  style={{ border: "1px solid #C3C3C3" }}
                >
                  <img src={exportIcon} alt="export icon" />
                  Export
                </button>
              </div>
              <div
                className="h-auto bg-white p-8 rounded-xl flex flex-wrap gap-8"
                style={{
                  background: "#FDFCFF",
                  border: "1px solid #DFDFDF",
                  color: "#171717",
                  fontSize: "16px",
                }}
              >
                <div
                  className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                  style={{
                    border: "1px solid #C2C2C2",
                    width: "398px",
                    height: "54px",
                    background: "white",
                    fontSize: "17px",
                    color: "#171717",
                  }}
                >
                  <span className=" w-1/2"> Call Date</span>
                  <span
                    className=" mx-8 h-10"
                    style={{ backgroundColor: "#828282", width: "1px" }}
                  ></span>
                  <span className="font-bold"> {data?.call_date || "-"}</span>
                </div>
                <div
                  className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                  style={{
                    border: "1px solid #C2C2C2",
                    width: "398px",
                    height: "54px",
                    background: "white",
                    fontSize: "17px",
                    color: "#171717",
                  }}
                >
                  <span className=" w-1/2">Agent</span>
                  <span
                    className=" mx-8 h-10"
                    style={{ backgroundColor: "#828282", width: "1px" }}
                  ></span>
                  <span className="font-bold">{data?.agent || "-"}</span>
                </div>
                <div
                  className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                  style={{
                    border: "1px solid #C2C2C2",
                    width: "398px",
                    height: "54px",
                    background: "white",
                    fontSize: "17px",
                    color: "#171717",
                  }}
                >
                  <span className=" w-1/2">Subscriber Name</span>
                  <span
                    className=" mx-8 h-10"
                    style={{ backgroundColor: "#828282", width: "1px" }}
                  ></span>
                  <span className="font-bold">
                    {data?.customer_name || "-"}
                  </span>
                </div>
                <div
                  className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                  style={{
                    border: "1px solid #C2C2C2",
                    width: "398px",
                    height: "54px",
                    background: "white",
                    fontSize: "17px",
                    color: "#171717",
                  }}
                >
                  <span className=" w-1/2">Team Lead</span>
                  <span
                    className=" mx-8 h-10"
                    style={{ backgroundColor: "#828282", width: "1px" }}
                  ></span>
                  <span className="font-bold">{data?.team_lead || "-"}</span>
                </div>
                <div
                  className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                  style={{
                    border: "1px solid #C2C2C2",
                    width: "398px",
                    height: "54px",
                    background: "white",
                    fontSize: "17px",
                    color: "#171717",
                  }}
                >
                  <span className=" w-1/2">Audit Date</span>
                  <span
                    className=" mx-8 h-10"
                    style={{ backgroundColor: "#828282", width: "1px" }}
                  ></span>
                  <span className="font-bold">{data?.audit_date || "-"}</span>
                </div>
                <div
                  className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                  style={{
                    border: "1px solid #C2C2C2",
                    width: "398px",
                    height: "54px",
                    background: "white",
                    fontSize: "17px",
                    color: "#171717",
                  }}
                >
                  <span className=" w-1/2">Language</span>
                  <span
                    className=" mx-8 h-10"
                    style={{ backgroundColor: "#828282", width: "1px" }}
                  ></span>

                  <span className="font-bold">{data?.language || "-"}</span>
                </div>
                <div
                  className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                  style={{
                    border: "1px solid #C2C2C2",
                    width: "398px",
                    height: "54px",
                    background: "white",
                    fontSize: "17px",
                    color: "#171717",
                  }}
                >
                  <span className=" w-1/2">Loan Product</span>
                  <span
                    className=" mx-8 h-10"
                    style={{ backgroundColor: "#828282", width: "1px" }}
                  ></span>
                  <span className="font-bold">{data?.product || "-"}</span>
                </div>
              </div>
              <div
                className="flex items-center justify-between bg-white px-4 py-4 rounded-xl "
                style={{ height: "56px" }}
              >
                <div className="flex justify-between items-center">
                  <span className=" flex gap-4">
                    <img src={callReport} alt="detail" />
                    <span
                      className="font-bold"
                      style={{ color: "#271078", fontSize: "16px" }}
                    >
                      Call Report
                    </span>
                  </span>
                </div>
                <span className=" flex items-center mr-14 gap-2">
                  <img src={score} alt="score" />
                  <span
                    className=" font-bold"
                    style={{ fontSize: "18px", color: "#271078" }}
                  >
                    {" "}
                    Total Score:
                  </span>
                  <span
                    style={{ fontSize: "18px", color: "#271078" }}
                    className=" font-bold"
                  >
                    {data?.total_points}
                  </span>
                </span>
                {/* <span className=" flex gap-4">
                  <img src={score} alt="detail" />
                  <span className=" font-bold" style={{ fontSize: "16px" }}>
                    Call Report
                  </span>
                </span> */}
              </div>
              {/* nest 3 box in one div */}
              <div className="flex gap-4">
                <div
                  className="w-1/3  rounded-xl py-8 px-8 flex flex-col gap-4"
                  style={{
                    backgroundColor: "#FCFCFF",
                    border: "1px solid #DFDFDF",
                    height: "auto",
                  }}
                >
                  <div
                    className="flex justify-between items-center mb-4 "
                    // style={{ marginBottom: "24px" }}
                  >
                    <span
                      className="flex gap-4 items-center "
                      style={{
                        color: "#484848",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      <img src={call} alt="call-opening" />
                      Call Opening
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={score} />{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "#271078",
                        }}
                      >
                        {data?.call_opening_points} Points
                      </span>
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Call Open Timely Manner
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.call_open_timely_manner
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.call_open_timely_manner ? "Yes" : "No"}
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Standard Opening Spiel
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.standard_opening_spiel
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.standard_opening_spiel ? "Yes" : "No"}
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Verification for Account Securi
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.verification_security
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.verification_security ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
                <div
                  className="w-1/3  rounded-xl py-8 px-8 flex flex-col gap-4"
                  style={{
                    backgroundColor: "#FCFCFF",
                    border: "1px solid #DFDFDF",
                    height: "auto",
                  }}
                >
                  <div
                    className="flex justify-between items-center mb-4 "
                    // style={{ marginBottom: "24px" }}
                  >
                    <span
                      className="flex gap-4 items-center "
                      style={{
                        color: "#484848",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      <img src={customer} alt="call-opening" />
                      Customer Experience
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={score} />{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "#271078",
                        }}
                      >
                        {data?.customer_experience_points} Points
                      </span>
                    </span>
                  </div>

                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "16px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Friendly Confident Tone
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.friendly_confident_tone
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.friendly_confident_tone ? "Yes" : "No"}
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Attentive Listening
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.attentive_listening
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.attentive_listening ? "Yes" : "No"}
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Call Control Efficiency
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.call_control_efficiency
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.call_control_efficiency ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
                <div
                  className="w-1/3  rounded-xl py-8 px-8 flex flex-col gap-4"
                  style={{
                    backgroundColor: "#FCFCFF",
                    border: "1px solid #DFDFDF",
                    height: "auto",
                  }}
                >
                  <div
                    className="flex justify-between items-center mb-4 "
                    // style={{ marginBottom: "24px" }}
                  >
                    <span
                      className="flex gap-4 items-center "
                      style={{
                        color: "#484848",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      <img src={process} alt="call-opening" />
                      Process Compliance
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={score} />{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "#271078",
                        }}
                      >
                        {data?.process_compliance_points} Points
                      </span>
                    </span>
                  </div>

                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Follow Policies Procedure
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.follow_policies_procedure
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.follow_policies_procedure ? "Yes" : "No"}
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Service Remainder
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.service_reaminder
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.service_reaminder ? "Yes" : "No"}
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Customer Alternative Number
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.customer_alternative_number
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.customer_alternative_number ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
              </div>

              {/* nested two boxes in one div */}
              <div className="flex gap-4">
                <div
                  className="rounded-xl py-8 px-8 flex flex-col gap-4"
                  style={{
                    backgroundColor: "#FCFCFF",
                    border: "1px solid #DFDFDF",
                    height: "auto",
                    width: "67.3%",
                  }}
                >
                  <div
                    className="flex justify-between items-center mb-4 "
                    // style={{ marginBottom: "24px" }}
                  >
                    <span
                      className="flex gap-4 items-center "
                      style={{
                        color: "#484848",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      <img src={serviceIcon} alt="call-opening" />
                      Service and Policy Compilance
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={score} />{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "#271078",
                        }}
                      >
                        {data?.service_compliance_points} Points
                      </span>
                    </span>
                  </div>

                  <div className=" flex gap-4 flex-wrap">
                    <div
                      className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                      style={{
                        border: "1px solid #C2C2C2",
                        width: "49%",
                        height: "54px",
                        background: "white",
                        fontSize: "17px",
                        color: "#171717",
                      }}
                    >
                      <span className="" style={{ width: "70%" }}>
                        {" "}
                        Call Record Clause
                      </span>
                      <span
                        className=" mx-8 h-10"
                        style={{ backgroundColor: "#828282", width: "1px" }}
                      ></span>
                      <span
                        className={
                          data?.call_record_clause
                            ? "text-green-600 font-bold"
                            : "text-red-600 font-bold"
                        }
                      >
                        {data?.call_record_clause ? "Yes" : "No"}
                      </span>
                    </div>
                    <div
                      className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                      style={{
                        border: "1px solid #C2C2C2",
                        width: "49%",
                        height: "54px",
                        background: "white",
                        fontSize: "17px",
                        color: "#171717",
                      }}
                    >
                      <span className="" style={{ width: "70%" }}>
                        {" "}
                        Call Avoidance
                      </span>
                      <span
                        className=" mx-8 h-10"
                        style={{ backgroundColor: "#828282", width: "1px" }}
                      ></span>
                      <span
                        className={
                          data?.call_avoidance
                            ? "text-green-600 font-bold"
                            : "text-red-600 font-bold"
                        }
                      >
                        {data?.call_avoidance ? "Yes" : "No"}
                      </span>
                    </div>
                    <div
                      className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                      style={{
                        border: "1px solid #C2C2C2",
                        width: "49%",
                        height: "54px",
                        background: "white",
                        fontSize: "17px",
                        color: "#171717",
                      }}
                    >
                      <span className="" style={{ width: "70%" }}>
                        {" "}
                        Pid Process
                      </span>
                      <span
                        className=" mx-8 h-10"
                        style={{ backgroundColor: "#828282", width: "1px" }}
                      ></span>
                      <span
                        className={
                          data?.pid_process
                            ? "text-green-600 font-bold"
                            : "text-red-600 font-bold"
                        }
                      >
                        {data?.pid_process ? "Yes" : "No"}
                      </span>
                    </div>
                    <div
                      className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                      style={{
                        border: "1px solid #C2C2C2",
                        width: "49%",
                        height: "54px",
                        background: "white",
                        fontSize: "17px",
                        color: "#171717",
                      }}
                    >
                      <span className="" style={{ width: "70%" }}>
                        {" "}
                        Misleading Information
                      </span>
                      <span
                        className=" mx-8 h-10"
                        style={{ backgroundColor: "#828282", width: "1px" }}
                      ></span>
                      <span
                        className={
                          data?.misleading_information
                            ? "text-green-600 font-bold"
                            : "text-red-600 font-bold"
                        }
                      >
                        {data?.misleading_information ? "Yes" : "No"}
                      </span>
                    </div>
                    <div
                      className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                      style={{
                        border: "1px solid #C2C2C2",
                        width: "49%",
                        height: "54px",
                        background: "white",
                        fontSize: "17px",
                        color: "#171717",
                      }}
                    >
                      <span className="" style={{ width: "70%" }}>
                        {" "}
                        UDCP Process
                      </span>
                      <span
                        className=" mx-8 h-10"
                        style={{ backgroundColor: "#828282", width: "1px" }}
                      ></span>
                      <span
                        className={
                          data?.udcp_process
                            ? "text-green-600 font-bold"
                            : "text-red-600 font-bold"
                        }
                      >
                        {data?.udcp_process ? "Yes" : "No"}
                      </span>
                    </div>
                    <div
                      className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                      style={{
                        border: "1px solid #C2C2C2",
                        width: "49%",
                        height: "54px",
                        background: "white",
                        fontSize: "17px",
                        color: "#171717",
                      }}
                    >
                      <span className="" style={{ width: "70%" }}>
                        {" "}
                        Data Manipulation
                      </span>
                      <span
                        className=" mx-8 h-10"
                        style={{ backgroundColor: "#828282", width: "1px" }}
                      ></span>
                      <span
                        className={
                          data?.data_manipulation
                            ? "text-green-600 font-bold"
                            : "text-red-600 font-bold"
                        }
                      >
                        {data?.data_manipulation ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="w-1/3  rounded-xl py-8 px-8 flex flex-col gap-4"
                  style={{
                    backgroundColor: "#FCFCFF",
                    border: "1px solid #DFDFDF",
                    height: "auto",
                  }}
                >
                  <div
                    className="flex justify-between items-center mb-4 "
                    // style={{ marginBottom: "24px" }}
                  >
                    <span
                      className="flex gap-4 items-center "
                      style={{
                        color: "#484848",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      <img src={callClosing} alt="call-opening" />
                      Call Closing
                    </span>

                    <span className="flex gap-2 items-center">
                      <img src={score} />{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "#271078",
                        }}
                      >
                        {data?.call_closing_points} Points
                      </span>
                    </span>
                  </div>

                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Call Recap
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.call_recap
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.call_recap ? "Yes" : "No"}
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Ask Additional Querries
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.additional_queries
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.additional_queries ? "Yes" : "No"}
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "16px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Call Closed Properly
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.call_closing
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.call_closing ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
              </div>
              {/* last box */}
              <div className="flex gap-4">
                <div
                  className="w-1/3  rounded-xl py-8 px-8 flex flex-col gap-4"
                  style={{
                    backgroundColor: "#FCFCFF",
                    border: "1px solid #DFDFDF",
                    height: "auto",
                  }}
                >
                  <div
                    className="flex justify-between items-center mb-4 "
                    // style={{ marginBottom: "24px" }}
                  >
                    <span
                      className="flex gap-4 items-center "
                      style={{
                        color: "#484848",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      <img src={call} alt="call-opening" />
                      Negotiation Strategy
                    </span>

                    <span className="flex gap-2 items-center">
                      <img src={score} />{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "#271078",
                        }}
                      >
                        {data?.negotiation_points} Points
                      </span>
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Probing Questions Effectiveness
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>

                    <span
                      className={
                        data?.probing_questions_effectiveness
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.probing_questions_effectiveness ? "Yes" : "No"}
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Payment Resolution Actions
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>

                    <span
                      className={
                        data?.payment_resolution_actions
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.payment_resolution_actions ? "Yes" : "No"}
                    </span>
                  </div>
                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Payment Delay Consequences
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.payment_delay_consequences
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.payment_delay_consequences ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
                <div
                  className="rounded-xl py-8 px-8 flex flex-col gap-4"
                  style={{
                    backgroundColor: "#FCFCFF",
                    border: "1px solid #DFDFDF",
                    height: "fit-content",
                    width: "32.5%",
                  }}
                >
                  <div
                    className="flex justify-between items-center mb-4 "
                    // style={{ marginBottom: "24px" }}
                  >
                    <span
                      className="flex gap-4 items-center "
                      style={{
                        color: "#484848",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      <img src={documentIcon} alt="call-opening" />
                      Document
                    </span>

                    {/* <span className="flex gap-2 items-center">
                      <img src={score} />{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "#271078",
                        }}
                      >
                        {data?.call_opening_points} Points
                      </span>
                    </span> */}
                  </div>

                  <div
                    className="px-6 py-4 flex  items-center  rounded-lg no whitespace-nowrap"
                    style={{
                      border: "1px solid #C2C2C2",
                      width: "100%",
                      height: "54px",
                      background: "white",
                      fontSize: "17px",
                      color: "#171717",
                    }}
                  >
                    <span className="" style={{ width: "70%" }}>
                      {" "}
                      Properly Document the Call
                    </span>
                    <span
                      className=" mx-8 h-10"
                      style={{ backgroundColor: "#828282", width: "1px" }}
                    ></span>
                    <span
                      className={
                        data?.call_document
                          ? "text-green-600 font-bold"
                          : "text-red-600 font-bold"
                      }
                    >
                      {data?.call_document ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Detailedreport;
