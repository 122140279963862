import React from "react";
import NavBar from "../../nav/nav-bar";
import Header from "../../header/header";
import ReportNav from "../report-nav";
import Footer from "../../footer/footer";
import { useState, useEffect } from "react";
import Modal from "react-modal";
import Service from "../../webservice/http";
import { Loader } from "rsuite";
import ClipLoader from "react-spinners/ClipLoader";
import { debounce } from "lodash";
function Criticalbusiness() {
  const [showNewModal, setShowNewModal] = useState(false);
  const services = new Service();
  const [tabledata, setTabledata] = useState([]);
  const [search, setSearch] = useState("");
  const [Loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [customerData, setCustomerData] = useState("");
  const [editdata, setEditdata] = useState({});
  const [showedit, setshowedit] = useState(false);

  const tableHeadingArray = [
    "Report Generation",
    "Call",
    "Audit ",
    "Call Language",
    "NEMP ID",
    "Application No",

    " Action",
    "Edit",
    // " Language  Preference",
    // " Welcoming the Customer",
    // " Time Affirmation ",
    // " Hold Prodcudure",
    // " Sr.Citizen",
    // " Personal Information",
    // " 2nd attempt of concern case",
    // " Purpose of insurance",
    // " Thanking on confirmation",
    // " AVF confirmation",
    // " Application Number",
    // " Policy Details",
    // " vesting plan",
    // " Coversion and Exit clause",
    // " Policy Document",
    // " My Account",
    // " Disclaimer",
    // " Probing",
  ];
  const handleInputChange = (event) => {
    // console.log("handleInputChange called");
    const { name, value } = event.target;
    // console.log(name, value);

    setEditdata({ [name]: value });
  };

  async function data() {
    setLoading(true);
    let url = `/api/pcvc/ctc_info/`;

    if (search) {
      url = url + `?search=${search}`;
    }

    const res = await services
      .get(url)

      .then((res) => {
        setTabledata(res?.data);
        setLoading(false);
        // console.log(res, "k");
      });
  }

  useEffect(() => {
    data();
  }, [search]);

  // console.log(Loading);

  async function viewMore() {
    let url = `api/pcvc/${id}/ctc/`;
    const res = await services.get(url).then((res) => {
      setCustomerData(res);
      setEditdata({
        call_opening: res?.call_opening,
        rpc: res?.rpc,
        nrpc: res?.nrpc,
        language_preference: res?.language_preference,
        welcoming_customer: res?.welcoming_customer,
        time_affermation: res?.time_affermation,
        hold_procedure: res?.hold_procedure,
        sr_citizen_confirmation: res?.sr_citizen_confirmation,
        personal_info_sharing_clause: res?.personal_info_sharing_clause,
        second_attempt_concern: res?.second_attempt_concern,
        purpose_of_insurance: res?.purpose_of_insurance,
        avf_confirmation: res?.avf_confirmation,
        advisor_application_number: res?.advisor_application_number,
        vesting_plan: res?.vesting_plan,
        conversion: res?.conversion,
        exit_clause: res?.exit_clause,
        policy_document: res?.policy_document,
        my_account_details: res?.my_account_details,
        probing: res?.probing,
        report_date: res?.report_date,
        call_date: res?.call_date,
        audit_date: res?.audit_date,
        nemp_id: res?.nemp_id,
        application_no: res?.application_no,
        language: res?.language,
      });
      // console.log(res,"kk");
    });
  }
  useEffect(() => {
    viewMore();
  }, [id]);

  const debouncedSearch = debounce((value) => {
    setSearch(value);
  }, 500);

  const handleSubmit = (event) => {
    event.preventDefault();
    services.patch(`api/pcvc/${id}/ctc/`, editdata).then((res) => {
      // console.log(res, "check");
    });
    setshowedit(false);
  };
  useEffect(() => {
    data();
  }, [showedit]);

  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />
      <div>
        <div className="container-fluid">
          <div className="my-call">
            <div
              className="side-panel-with-table clearfix"
              style={{ display: "flex" }}
            >
              <ReportNav />
              <div className="call-table " style={{ marginBottom: "80px" }}>
                <div className="my-calls-column">
                  <div className="calls-top-pannel">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="pannel-heading clearfix">
                          <div className="pannel-heading-icon">
                            <i className="flaticon-incoming-call"></i>
                          </div>
                          <div className="pannel-heading-info">
                            <h3>Critical To Customer</h3>
                            <p> Details</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" flex justify-end mb-4 ">
                  <input
                    onChange={(event) => debouncedSearch(event.target.value)}
                    type="text"
                    placeholder="Search..."
                    className="search-tag"
                    style={{
                      // border: "1px solid transparent",
                      border: "none",
                      background: "#eef2f5",
                      borderRadius: "10px",
                      width: "250px",
                      outline: "none",
                      padding: "10px",
                    }}
                  />
                </div>
                <div className=" overflow-y-scroll">
                  <table className="ss">
                    <tr>
                      <th
                        colSpan="3"
                        className="left-0 bg-white  px-5 py-4  text-center  text-xl font-semibold tracking-wider text-black ss-th "
                        style={{
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        Date
                      </th>
                      <th
                        colSpan="7"
                        style={{ borderBottom: "1px solid lightgrey" }}
                        // className="left-0 bg-white  px-5 py-4  text-center  text-xl font-semibold uppercase tracking-wider text-black ss-th "
                      >
                        {/* Other Informations */}
                      </th>
                    </tr>
                    <tr id="black-line">
                      {tableHeadingArray.map((item, indx) => (
                        <th
                          key={item}
                          className={`
                               left-0 bg-white  border-b-2 border-gray-200 px-5 py-4 text-left  text-xl font-semibold  tracking-wider text-black  
                                 ${indx === 3 ? " sticky-top left-0" : ""}
                               }  `}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>

                    <tbody>
                      {Loading && (
                        <tr>
                          <td colSpan="9" style={{ textAlign: "center" }}>
                            <ClipLoader color="#2056FF" size="50px" />
                          </td>
                        </tr>
                      )}
                      {tabledata.length ? (
                        tabledata?.map((e) => (
                          <tr>
                            <td
                              className={`border-b border-gray-200 px-5 py-2  w-40 text-xs`}
                            >
                              <div>
                                <p className="whitespace-no-wrap cursor-pointer text-center text-sky-700">
                                  {e?.report_date}
                                </p>
                              </div>
                            </td>
                            <td
                              className={`border-b border-gray-200 px-5 py-2 w-40 text-xs`}
                            >
                              <p className="whitespace-no-wrap text-left text-gray-900">
                                {e?.call_date}
                              </p>
                            </td>
                            <td
                              className={`border-b border-gray-200 px-5 py-2   w-40 text-xs`}
                            >
                              <p className="whitespace-no-wrap text-left text-gray-900">
                                {e?.audit_date}
                              </p>
                            </td>
                            <td
                              className={`border-b border-gray-200 px-5 py-2  w-40 text-xs`}
                            >
                              <p className="whitespace-no-wrap text-left text-gray-900">
                                {e?.language}
                              </p>
                            </td>
                            <td
                              className={`border-b border-gray-200 px-5 py-2  w-40 text-xs sticky left-0 `}
                            >
                              <p className="whitespace-no-wrap text-left text-gray-900">
                                {e?.nemp_id}
                              </p>
                            </td>
                            <td
                              className={`border-b border-gray-200 px-5 py-2  w-40 text-xs`}
                            >
                              <p className="whitespace-no-wrap text-left text-gray-900">
                                {e?.application_no}
                              </p>
                            </td>

                            <td
                              className={`border-b border-gray-200 px-5 py-2  w-40 text-xs cursor-pointer`}
                            >
                              <a
                                className="text-left text-gray-900 whitespace-nowrap"
                                onClick={() => {
                                  setId(e?.id);
                                  setShowNewModal(true);
                                  // viewMore();
                                }}
                              >
                                view more
                              </a>
                            </td>
                            <td
                              className={`border-b border-gray-200 px-5 py-2  w-40 text-xs cursor-pointer`}
                              onClick={() => {
                                setId(e?.id);
                                setshowedit(true);
                              }}
                            >
                              <i
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>
                            </td>
                          </tr>
                        ))
                      ) : Loading ? (
                        ""
                      ) : (
                        <tr>
                          <td colSpan="11" style={{ textAlign: "center" }}>
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showNewModal}
        overlayClassName="modal-overlay"
        className="modal-content"
      >
        <div className="col-md-12">
          <div className="pannel-heading clearfix mt-4">
            <div className="pannel-heading-icon">
              <i className="fa fa-snowflake-o" aria-hidden="true"></i>
            </div>
            <div className="pannel-heading-info">
              <h3>Detailed Report</h3>
            </div>
          </div>
          <div
            className=" absolute cursor-pointer"
            style={{ top: "-13px", right: "-9px" }}
            onClick={() => setShowNewModal(false)}
          >
            <i
              class="fa fa-times-circle"
              aria-hidden="true"
              style={{ fontSize: "25px" }}
            ></i>
          </div>

          <div className="bg-gray-100 flex space-x-4 p-6">
            <div className="w-full">
              <div className="bg-white rounded p-4 pt-6">
                <div className="px-2 flex">
                  <div className="w-1/2 flex text-sm border-r border-gray-300">
                    <div className="date">
                      <div className="flex text-2xl pt-2 flex-col text-gray font-bold">
                        <div className="mb-4">
                          Report:
                          <span className="text-black ml-4">
                            {customerData?.report_date}
                          </span>
                        </div>
                        <div className="mb-4">
                          Call:
                          <span className="text-black ml-4">
                            {customerData?.call_date}
                          </span>
                        </div>
                        <div className="">
                          Audit:
                          <span className="text-black ml-4">
                            {customerData?.audit_date}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2 pl-6 flex text-2xl text-gray font-bold mt-2">
                    <div className="w-1/2" style={{ maxWidth: "120px" }}>
                      <div className="mr-2">NEMP ID:</div>
                      <div className="mr-2 mt-3">Application No:</div>
                      <div className="mr-2 mt-3">Language:</div>
                    </div>
                    <div className="w-1/2">
                      <div className="text-black ml-4">
                        {customerData?.nemp_id}
                      </div>
                      <div className="text-black ml-4 mt-3">
                        {customerData?.application_no}
                      </div>
                      <div className="text-black ml-4 mt-3">
                        {customerData?.language}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="py-2 flex"></div> */}
                <div className="px-2 itemDetails border-t mt-4 pt-4">
                  <div className="flex">
                    <div className="w-full  border-gray-300 text-2xl text-gray font-bold">
                      <div className="mb-2">
                        Agent Professionalism:{" "}
                        <span className="text-black">
                          {customerData?.agent_professionalism ? "Yes" : "No"}
                        </span>
                      </div>
                      <div className="mb-2">
                        Objection Handling:{" "}
                        <span className="text-black">
                          {customerData?.objection_handling ? "Yes" : "No"}
                        </span>
                      </div>
                      <div className="mb-2">
                        Hold Procedure:{" "}
                        <span className="text-black">
                          {customerData?.hold_procedure ? "Yes" : "No"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="popup-footer">
            <>
              <button
                className="btn"
                style={{ padding: "9px" }}
                type="button"
                onClick={() => setShowNewModal(false)}
              >
                Close
              </button>
              {/* <button className="btn" style={{ padding: "9px" }} type="button">
                Send Email
              </button> */}
            </>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showedit}

        // style={customStyles}
      >
        <form onSubmit={handleSubmit}>
          <div className="col-md-12 editpanel">
            <div
              className="pannel-heading clearfix"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="pannel-heading-icon">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              </div>
              <div className="pannel-heading-info">
                <h3>Detailed Report</h3>
              </div>
            </div>
            <div
              className="absolute cursor-pointer"
              onClick={() => setshowedit(false)}
              style={{ top: "-13px", right: "-9px" }}
            >
              <i
                className="fa fa-times-circle"
                aria-hidden="true"
                style={{ fontSize: "25px" }}
              ></i>
            </div>

            <div className="bg-gray-100 flex space-x-4 p-6">
              <div className="w-full">
                <div className="bg-white rounded p-4 pt-6">
                  <div className="px-2 flex">
                    <div className="w-1/2 flex text-sm border-r border-gray-300">
                      <div className="flex text-2xl pt-2 flex-row text-gray font-bold justify-around w-1/2">
                        <div className=" flex flex-col">
                          <div className="" style={{ marginBottom: "22px" }}>
                            Report :
                          </div>
                          <div className="" style={{ marginBottom: "22px" }}>
                            Call :
                          </div>
                          <div> Audit :</div>
                        </div>
                        <div className=" flex flex-col">
                          <div className="mb-4">
                            <input
                              className="text-black ml-4"
                              type="text"
                              name="report_date"
                              value={editdata.report_date}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="mb-4">
                            <input
                              className="text-black ml-4"
                              type="text"
                              name="call_date"
                              value={editdata.call_date}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div>
                            <div className="">
                              <input
                                className="text-black ml-4"
                                type="text"
                                name="audit_date"
                                value={editdata.audit_date}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-1/2 flex text-sm border-r border-gray-300">
                      <div className="flex text-2xl pt-2 flex-row text-gray font-bold justify-around w-1/2 ml-6">
                        <div className=" flex flex-col">
                          <div className="" style={{ marginBottom: "20px" }}>
                            {" "}
                            NEMP ID :
                          </div>
                          <div className="" style={{ marginBottom: "20px" }}>
                            Application No :
                          </div>
                          <div>Language :</div>
                        </div>
                        <div className=" flex flex-col">
                          <div className=" mb-1">
                            <input
                              className="text-black ml-4"
                              type="text"
                              name="nemp_id"
                              value={editdata.nemp_id}
                              onChange={handleInputChange}
                            />{" "}
                          </div>
                          <div className=" mb-1">
                            <input
                              className="text-black ml-4 mt-2"
                              type="text"
                              name="application_no"
                              value={editdata.application_no}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div>
                            <div className="">
                              <input
                                className="text-black ml-4 mt-2"
                                type="text"
                                name="language"
                                value={editdata.language}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-2 itemDetails border-t mt-4 pt-4">
                    <div className="px-2 flex">
                      <div className="w-1/2 flex text-sm border-r border-gray-300">
                        <div className="flex text-2xl pt-2 flex-row text-gray font-bold justify-around w-1/2">
                          <div className=" flex flex-col">
                            <div className="" style={{ marginBottom: "22px" }}>
                              Opening :
                            </div>
                            <div className="" style={{ marginBottom: "22px" }}>
                              RPC :
                            </div>
                            <div>NRPC :</div>
                          </div>
                          <div className=" flex flex-col">
                            <div className="mb-4">
                              <input
                                className="text-black ml-4"
                                type="text"
                                name="call_opening"
                                value={editdata.call_opening}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="mb-4">
                              <input
                                className="text-black ml-4"
                                type="text"
                                name="rpc"
                                value={editdata.rpc ? "Yes" : "No"}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="mb-4">
                              <input
                                className="text-black ml-4"
                                type="text"
                                name="nrpc"
                                value={editdata.nrpc ? "Yes" : "No"}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-1/2 flex text-sm border-r border-gray-300">
                        <div className="flex text-2xl pt-2 flex-row text-gray font-bold justify-around  ml-6">
                          <div className=" flex flex-col">
                            <div
                              className=" whitespace-nowrap"
                              style={{ marginBottom: "20px" }}
                            >
                              {" "}
                              Language Preference :
                            </div>
                            <div
                              className=" whitespace-nowrap"
                              style={{ marginBottom: "20px" }}
                            >
                              {" "}
                              Welcoming the Customer :
                            </div>
                            <div className=" whitespace-nowrap">
                              Time Affirmation :
                            </div>
                          </div>
                          <div className=" flex flex-col">
                            <div className="mb-2">
                              <input
                                className="text-black ml-4"
                                type="text"
                                name="language_preference"
                                value={editdata.language_preference}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="mb-2">
                              <input
                                className="text-black ml-4 mt-2"
                                type="text"
                                name="welcoming_customer"
                                value={
                                  editdata.welcoming_customer ? "Yes" : "No"
                                }
                                onChange={handleInputChange}
                              />
                            </div>

                            <div className="mb-2">
                              <input
                                className="text-black ml-4 mt-2  "
                                type="text"
                                name="time_affermation"
                                value={editdata.time_affermation ? "Yes" : "No"}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white rounded px-4 pt-4 pb-3 mt-6">
                  <div className="px-2 flex">
                    <div className="w-1/2 flex text-sm border-r border-gray-300">
                      <div className="flex text-2xl pt-2 flex-row text-gray font-bold justify-around w-1/2">
                        <div className=" flex flex-col">
                          <div className="" style={{ marginBottom: "22px" }}>
                            Hold Procedure :
                          </div>
                          <div className="" style={{ marginBottom: "22px" }}>
                            Senior Citizen :
                          </div>
                        </div>
                        <div className=" flex flex-col">
                          <div className="mb-4">
                            <input
                              className="text-black ml-4"
                              type="text"
                              name="hold_procedure"
                              value={editdata.hold_procedure ? "Yes" : "No"}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="mb-4">
                            <input
                              className="text-black ml-4"
                              type="text"
                              name="sr_citizen_confirmation"
                              value={
                                editdata.sr_citizen_confirmation ? "Yes" : "No"
                              }
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-1/2 flex text-sm border-r border-gray-300">
                      <div className="flex text-2xl pt-2 flex-row text-gray font-bold justify-around  ml-6">
                        <div className=" flex flex-col">
                          <div
                            className=" whitespace-nowrap"
                            style={{ marginBottom: "20px" }}
                          >
                            {" "}
                            Personal Information :
                          </div>
                          <div
                            className=" whitespace-nowrap"
                            style={{ marginBottom: "20px" }}
                          >
                            {" "}
                            Second Attempt :
                          </div>
                        </div>
                        <div className=" flex flex-col">
                          <div className="mb-2">
                            <input
                              className="text-black ml-4"
                              type="text"
                              name="personal_info_sharing_clause"
                              value={
                                editdata.personal_info_sharing_clause
                                  ? "Yes"
                                  : "No"
                              }
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="mb-2">
                            <input
                              className="text-black ml-4 "
                              type="text"
                              name="second_attempt_concern"
                              value={
                                editdata.second_attempt_concern ? "Yes" : "No"
                              }
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded px-4 pt-4 pb-3 mt-6">
                  <div className="px-2 flex mb-1 justify-between">
                    <div className="flex flex-col w-1/2 border-r">
                      <div className="w-full flex text-sm border-gray-800">
                        <div className="date">
                          <div className="flex items-center text-2xl py-2 font-bold text-gray">
                            <div className="">Purpose of Insurance :</div>
                            <input
                              className="text-black "
                              style={{ marginLeft: "44px" }}
                              type="text"
                              name="purpose_of_insurance"
                              value={
                                editdata.purpose_of_insurance ? "Yes" : "No"
                              }
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex text-sm border-gray-800">
                        <div className="date">
                          <div className="flex items-center text-2xl py-2 font-bold text-gray">
                            <div className="">Thanking on Confirmation :</div>
                            <input
                              className="text-black ml-4 "
                              type="text"
                              name="thankingOnConfirmation"
                              value={editdata.thankingOnConfirmation}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col w-1/2 pl-6">
                      <div className="w-full flex text-sm border-gray-800">
                        <div className="date">
                          <div className="flex items-center text-2xl py-2 font-bold text-gray">
                            <div className="">AVF Confirmation :</div>
                            <input
                              className="text-black"
                              style={{ marginLeft: "44px" }}
                              type="text"
                              name="avf_confirmation"
                              value={editdata.avf_confirmation ? "Yes" : "No"}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex text-sm border-gray-800">
                        <div className="date">
                          <div className="flex items-center text-2xl py-2 font-bold text-gray">
                            <div className="">Advisor Application No :</div>
                            <input
                              className="text-black ml-4 "
                              type="text"
                              name="advisor_application_number"
                              value={
                                editdata.advisor_application_number
                                  ? "Yes"
                                  : "No"
                              }
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white rounded px-4 pt-4 pb-3 mt-6">
                  <div className="px-2 flex mb-1 justify-between">
                    <div className="flex flex-col w-1/2 border-r">
                      <div className="w-full flex text-sm border-gray-800">
                        <div className="date">
                          <div className="flex items-center text-2xl py-2 font-bold text-gray">
                            <div className="">Vesting Plan :</div>
                            <input
                              className="text-black ml-4 "
                              type="text"
                              name="vesting_plan"
                              value={editdata.vesting_plan ? "Yes" : "No"}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex text-sm border-gray-800">
                        <div className="date">
                          <div className="flex items-center text-2xl py-2 font-bold text-gray">
                            <div className="">Conversion :</div>
                            <input
                              className="text-black"
                              style={{ marginLeft: "18px" }}
                              type="text"
                              name="conversion"
                              value={editdata.conversion ? "Yes" : "No"}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col w-1/2 pl-6">
                      <div className="w-full flex text-sm border-gray-800">
                        <div className="date">
                          <div className="flex items-center text-2xl py-2 font-bold text-gray">
                            <div className="">Exit Clause :</div>
                            <input
                              className="text-black "
                              style={{ marginLeft: "50px" }}
                              type="text"
                              name="exit_clause"
                              value={editdata.exit_clause ? "Yes" : " No"}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex text-sm border-gray-800">
                        <div className="date">
                          <div className="flex items-center text-2xl py-2 font-bold text-gray">
                            <div className="">Policy Document :</div>
                            <input
                              className="text-black ml-4 "
                              type="text"
                              name="policy_document"
                              value={editdata.policy_document ? "Yes" : " No"}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white rounded px-4 pt-4 pb-3 mt-6">
                  <div className="px-2 flex mb-1 justify-between">
                    <div className="flex flex-col w-1/2 border-r">
                      <div className="w-full flex text-sm border-gray-800">
                        <div className="date">
                          <div className="flex items-center text-2xl py-2 font-bold text-gray">
                            <div className="">My Account :</div>
                            <input
                              className="text-black ml-4 "
                              type="text"
                              name="my_account_details"
                              value={editdata.my_account_details ? "Yes" : "No"}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex text-sm border-gray-800">
                        <div className="date">
                          <div className="flex items-center text-2xl py-2 font-bold text-gray">
                            <div className="">Disclaimer :</div>
                            <input
                              className="text-black "
                              style={{ marginLeft: "20px" }}
                              type="text"
                              name="disclaimer"
                              value={editdata.disclaimer ? "Yes" : "No"}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col w-1/2 pl-6">
                      <div className="w-full flex text-sm border-gray-800">
                        <div className="date">
                          <div className="flex items-center text-2xl py-2 font-bold text-gray">
                            <div className="">Probing :</div>
                            <input
                              className="text-black ml-4 "
                              type="text"
                              name="probing"
                              value={editdata.probing ? "Yes" : "No"}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-2 flex mt-6 justify-end">
                  <button
                    type="submit"
                    className="text-white bg-blue-500  rounded-lg"
                    style={{ padding: "15px 25px 15px 25px" }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <Footer />
    </div>
  );
}

export default Criticalbusiness;
