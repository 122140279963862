import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import NavBar from "../nav/nav-bar";
import ReportNav from "./report-nav";
import { useState } from "react";
// import axios from "axios";
import Service from "../webservice/http";
import { useEffect } from "react";
import EquityViewmore from "./equityViewMore";

function Equity() {
  const [open, setOpen] = useState(false);

  const [data, setData] = useState();

  const [id, setid] = useState();
  const services = new Service();
  function fetchData() {
    services.get("api/product/equity_list/").then((res) => {
      // console.log(res);
      setData(res);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  // console.log(data);
  return (
    <div>
      <Header />
      <NavBar />
      <div>
        <div className="container-fluid">
          <div className="my-call">
            <div
              className="side-panel-with-table clearfix"
              style={{ display: "flex" }}
            >
              <ReportNav />
              <div className="call-table">
                <div className="my-calls-column">
                  <div className="calls-top-pannel">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="pannel-heading clearfix">
                          <div className="pannel-heading-icon">
                            <i
                              className="fa fa-snowflake-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="pannel-heading-info">
                            <h3>Equity</h3>
                            <p> Details</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <table className="ss">
                  <thead>
                    <tr>
                      <th>
                        <p>Name</p>
                      </th>
                      <th>
                        <p>Profile Id</p>
                      </th>
                      <th>
                        <p>Company</p>
                      </th>

                      <th>
                        <p>Buy</p>
                      </th>
                      <th>
                        <p>Sell</p>
                      </th>
                      <th>
                        <p>Shares</p>
                      </th>
                      <th>
                        <p>Price</p>
                      </th>

                      <th>
                        <p>Amount</p>
                      </th>
                    </tr>
                  </thead>
                  {/* table with hypen value  */}

                  <tbody>
                    {data?.map((e) => (
                      <tr
                        onClick={() => {
                          setid(e?.id);
                          setOpen(true);
                        }}
                      >
                        <td>
                          <p className="blue">{e?.name}</p>
                        </td>
                        <td>
                          <p>{e?.profile_id}</p>
                        </td>
                        <td>
                          <p className="">{e?.company_name}</p>
                        </td>
                        <td>
                          <p>{e?.buy == true ? "Buy" : "-"}</p>
                        </td>
                        <td>
                          <p>{e?.sell ? "Sell" : "-"}</p>
                        </td>
                        <td>
                          <p>{e?.shares}</p>
                        </td>

                        <td
                          style={{ alignItems: "center", whiteSpace: "nowrap" }}
                        >
                          <p>{e?.price}</p>
                        </td>
                        <td
                          style={{ alignItems: "center", whiteSpace: "nowrap" }}
                        >
                          <p>{e?.amount}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {open && <EquityViewmore orderId={id} setOpen={setOpen} />}
    </div>
  );
}

export default Equity;
