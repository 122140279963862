import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visible: false,
};

export const visibleSlice = createSlice({
  name: "display",
  initialState,
  reducers: {
    makeVisible: (state) => {
      state.visible = true;
    },
    makeVanish: (state) => {
      state.visible = false;
    },
  },
});

export const { makeVisible, makeVanish } = visibleSlice.actions;
export default visibleSlice.reducer;
