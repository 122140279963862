const Dashscript = [
  {
    "Report Generation Date": "15-12-2022",
    "Call Date": " 11-11-2022",
    "Audit Date": "05-12-2022    ",
    "NEMP ID": " NEMP111111",
    "Application No": "1210000000",
    "Call Language": "English",
    "Critical to Business": "100%",
    "Critical to Customer": "100%",
    "Critical to Regulatory": "100%",
    "Non Critical": "100.00%",
    "Call Status": "Non Fatal",
  },
  {
    "Report Generation Date": "15-12-2022",
    "Call Date": " 11-11-2022",
    "Audit Date": "05-12-2022    ",
    "NEMP ID": " NEMP111111",
    "Application No": "1210000000",
    "Call Language": "English",
    "Critical to Business": "100%",
    "Critical to Customer": "0%",
    "Critical to Regulatory": "100%",
    "Non Critical": "87.50%",
    "Call Status": "Fatal",
  },
  {
    "Report Generation Date": "15-12-2022",
    "Call Date": " 11-11-2022",
    "Audit Date": "05-12-2022    ",
    "NEMP ID": " NEMP111111",
    "Application No": "1210000000",
    "Call Language": "English",
    "Critical to Business": "100%",
    "Critical to Customer": "0%",
    "Critical to Regulatory": "100%",
    "Non Critical": "62.50%",
    "Call Status": "Non Fatal",
  },
  {
    "Report Generation Date": "15-12-2022",
    "Call Date": " 11-11-2022",
    "Audit Date": "05-12-2022    ",
    "NEMP ID": " NEMP111112",
    "Application No": "1210000000",
    "Call Language": "Hindi",
    "Critical to Business": "100%",
    "Critical to Customer": "0%",
    "Critical to Regulatory": "100%",
    "Non Critical": "62.50%",
    "Call Status": "Non Fatal",
  },
];

export default Dashscript;
