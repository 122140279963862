import React from "react";
import { useState, useEffect } from "react";
import Service from "../webservice/http";

function Viewmore({ setShowNewModal, id }) {
  const [data, setdata] = useState();
  const services = new Service();
  //   console.log(orderId);

  function fetchData() {
    services.get(`api/pcvc/digitalreach_dashboard_list/${id}/`).then((res) => {
      console.log(res);
      setdata(res);
    });
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="modal my-calls-popup show">
      <div className="backdrop"></div>
      <div className="modal-dialog " role="document" id="m-pop">
        <div className="my-calls-popup-details">
          <h2>Detailed Report</h2>
          <div className="my-calls-form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label> Call to action :</label>
                  <span id="p-detail" className=" font-bold">
                    {data?.call_to_action ? "True" : "False"}
                  </span>

                  <br />
                </div>
                <div className="form-col clearfix">
                  <label>Information conveyed :</label>
                  <span id="p-detail" className=" font-bold">
                    {data?.information_conveyed ? "True" : "False"}
                  </span>
                </div>
              </div>
              {/* {Name		Company	Buy	Sell	Shares	Price	Amount} */}
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label> Lead generation correctness :</label>
                  <span id="p-detail" className=" font-bold">
                    {data?.lead_generation_correctness ? "True" : "False"}
                  </span>
                </div>
                <div className="form-col clearfix">
                  <label>Product explanation :</label>
                  <span id="p-detail" className=" font-bold">
                    {data?.product_explanation ? "True" : "False"}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label>Customer purchasing interest :</label>
                  <span id="p-detail" className=" font-bold">
                    {data?.customer_purchasing_interest ? "True" : "False"}
                  </span>
                </div>

                <div className="form-col clearfix">
                  <label>Sales pitch alignment:</label>
                  <span id="p-detail" className=" font-bold">
                    {data?.sales_pitch_alignment ? "True" : "False"}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label> Hold time" :</label>
                  <span id="p-detail" className=" font-bold">
                    {" "}
                    {data?.hold_time ? "True" : "False"}
                  </span>
                </div>
                <div className="form-col clearfix">
                  <label>Competition mentions</label>
                  <span id="p-detail" className=" font-bold">
                    {data?.competition_mentions ? "True" : "False"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="border"></div>
          <div className="popup-footer">
            <>
              {/* <button className="btn" data-dismiss="modal" type="button">
                {" "}
                Edit
              </button> */}
              {/* <button
              onClick={() => {
                setOpen(false);
              }}
              className="btn"
              data-dismiss="modal"
              type="button"
            >
              Save
            </button> */}
              <button
                onClick={() => {
                  setShowNewModal(false);
                }}
                className={`  px-8 py-4 rounded-xl export-btn tracking-wide mr-4 bg-gray-200 text-black   hover:bg-orange-600 hover:text-white  font-medium`}
                data-dismiss="modal"
                type="button"
                // style={{ borderRadius: "10px" }}
              >
                Close
              </button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Viewmore;
