export const Items = [
  {
    title: "Traditional/Conventional Plans",
    submenu: [
      { title: "Sanchay" },
      { title: "Sanchay Plus" },
      { title: "Guaranteed Savings Plan" },
      { title: "Sanchay Fixed Maturity Plan" },
    ],
  },
  {
    title: "ULIP Plans",
    submenu: [
      { title: "SL YoungStar Super Premium" },
      { title: "SL Crest" },
      { title: "SL Progrowth Super II" },
      { title: "SL Progrowth Flexi" },
      { title: "Progrowth Plus" },
      { title: "Click 2 Invest" },
      { title: "Capital Shield" },
      { title: "Smart Woman" },
      { title: "Click 2 Wealth" },
      { title: "Sampoorn Nivesh" },
      { title: "Classic One" },
    ],
  },
  {
    title: "Participating Plans",
    submenu: [
      { title: "Sanchay Par Advantage" },
      { title: "Super Income" },
      { title: "Uday" },
      { title: "Young Star Udaan" },
      { title: "Classic Assure Plus" },
      { title: "Sampoorn Samriddhi Plus" },
    ],
  },
  {
    title: "Term Plan",
    submenu: [
      { title: "Click 2 Protect Life" },
      { title: "Saral Jeevan Beema" },
    ],
  },
  {
    title: "Health Plans",
    submenu: [
      { title: "Cardiac Care" },
      { title: "Cancer Care" },
      { title: "Easy Health" },
    ],
  },

  {
    title: "Pension Plans",
    submenu: [
      { title: "Guaranteed Pension Plan" },
      { title: "Personal Pension Plus" },
      { title: "Assured Pension Plan" },
      { title: "Systematic Pension Plan" },
      { title: "Smart Second Innings Plan" },
    ],
  },
  {
    title: "Annuity Plans",
    submenu: [
      { title: "Pension Guaranteed Plan" },
      { title: "New Immediate Annuity Plan" },
      { title: "Systematic Retirement Plan" },
    ],
  },
];
