let API_BASE_URL = "https://fb.dataklout.com"; // Fallback default

export const initializeConfig = async () => {
  try {
    const response = await fetch("/config.json");
    const data = await response.json();
    API_BASE_URL = data.API_BASE_URL || API_BASE_URL;
  } catch (error) {
    console.error("Failed to load config, using default:", error);
  }
};

const config = {
  get API_BASE_URL() {
    return API_BASE_URL;
  },
};

export default config;
